<template>
  <div class="page-body">
    <Header/>
    <title-section
      title="Contact Us"
      desc="At  Deutsche, you can be sure of receiving expert advice on the available options before treatment begins, and confident of being in safe hands throughout."
    />
    <b-container class="page-content">
      <div class="section1">
        <h2>Send us a message</h2>
        <p>Complete the form on this page and we’ll get back to you as soon as possible</p>
        <div class="form-wrap">
          <b-row>
            <b-col cols="12" md="6"><b-form-input v-model="custName" placeholder="Your Name"/>
            </b-col>
            <b-col cols="12" md="6"><b-form-input v-model="phoneNumber" placeholder="Phone Number"/>
            </b-col>
          </b-row>
          <b-form-input v-model="emailAdd" placeholder="Email address"/>
          <b-textarea v-model="custMessage"></b-textarea>
          <b-button :disabled="disableBtn" variant="primary" class="ml-auto" @click="sendMessage()">Send  Message</b-button>
        </div>
      </div>
      <div class="section2">
        <dentist-search-tiles/>
      </div>
    </b-container>
    <Footer/>
  </div>
</template>

<script>
import api from '../api'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TitleSection from '../components/TitleSection.vue'
import DentistSearchTiles from '../components/DentistSearchTiles.vue'

export default {
  name: 'ContactUs',
  data () {
    return {
      disableBtn: false,
      custName: '',
      phoneNumber: '',
      emailAdd: '',
      custMessage: ''
    }
  },
  components: {
    Header,
    Footer,
    TitleSection,
    DentistSearchTiles
  },
  methods: {
    resetForm: function () {
      this.custName = ''
      this.phoneNumber = ''
      this.emailAdd = ''
      this.custMessage = ''
      this.disableBtn = false
    },
    sendMessage: function () {
      this.disableBtn = true
      const inputs = {
        'form-data': {
          name: this.custName,
          email: this.emailAdd,
          phone: this.phoneNumber,
          message: this.custMessage
        }
      }
      console.log('Send Message input', inputs)
      api
        .request('post', 'PortalApis/contact_form', inputs, true)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            this.disableBtn = false
          } else {
            console.log('Send Message Response:', data)
            this.$bvToast.toast('Your message has been received. We will respond to you as early as possible.', {
              title: 'Message Received!',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            this.resetForm()
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-body{
  .title-section{
    padding-top: 70px;
    padding-bottom: 150px;
  }
}
.section1{
  background-color: #fff;
  border: 1px solid #DFE5EF;
  text-align: center;
  padding-top: 70px;
  h2{
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 25px;
  }
  p{
    margin-bottom: 60px;
  }
  .form-wrap{
    margin: 0 auto;
    max-width: 770px;
    text-align: right;
    padding-bottom: 45px;
    .form-control{
      margin-bottom: 25px;
      background-color: #F2F5F9;
    }
  }
}
.section2{
  margin: 100px 0;
  text-align: center;
}
@media (min-width:768px) {
}
@media (min-width:992px) {
  .section1{
    max-width: 1030px;
    margin: 0 auto;
    margin-top: -70px;
    border-radius: 8px;
  }
}
</style>

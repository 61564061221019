<template>
  <div class="page-body">
    <Header/>
    <title-section
      title="Why Deutsche Align"
      desc="At  Deutsche, you can be sure of receiving expert advice on the available options before treatment begins, and confident of being in safe hands throughout."
    />
    <b-container class="page-content">
      <div class="section1">
        <b-tabs content-class="content" nav-class="tab-header" align="center">
          <b-tab title="Comfort & Predictability" active>
            <b-img :src="require('@/assets/images/why/comfort.jpg')" fluid></b-img>
          </b-tab>
          <b-tab title="Doctor Care"><p>I'm the second tab</p></b-tab>
          <b-tab title="Custom"><p>I'm the tab with the very, very long title</p></b-tab>
          <b-tab title="Treatment"><p>I'm a disabled tab!</p></b-tab>
          <b-tab title="Lasting Results"><p>I'm a disabled tab!</p></b-tab>
          <b-tab title="FAQ"><p>I'm a disabled tab!</p></b-tab>
        </b-tabs>
      </div>
      <div class="section2">
        <h2>Be confident and persuade your patient with Deutsche Aligners</h2>
          <b-card-group deck>
            <b-card class="yellow">
              <p>You don't need refiners to get satisfactory results and to achieve the final result as promised</p>
            </b-card>
            <b-card class="blue">
              <p>Highly monitors the treatment progress for each case</p>
            </b-card>
            <b-card class="purple">
              <p>Free of charge for the treatment plan proposal including video animation, IPR report and 3D models for Before and After</p>
            </b-card>
          </b-card-group>
      </div>
      <b-row class="section3">
        <b-col cols="12" md="6">
          <b-img :src="require('@/assets/images/why/smart-flx-material.jpg')" fluid></b-img>
        </b-col>
        <b-col cols="12" md="6" class="wrap-col">
          <div class="wrap">
            <h3>We use smart FLX material combined by three layers.</h3>
            <p>-Inner shell to firmly grip teeth</p>
            <p>-Outer shell to provide a tough barrier against bruxing or grinding from the opposing dental arch
            <p>-While a third, central layer provides elastic rebound</p>
            <p>(The real effective of Deutsche Aligner)</p>
            <b-button>Am I Suitable</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="section4">
        <b-col cols="12" md="6">
          <h3>What can Deutsche Align treat?</h3>
          <p>Crowding</p>
          <p>Crooked teeth</p>
          <p>Gaps between teeth</p>
          <p>Crooked teeth</p>
          <p>Gaps between teeth</p>
          <p>General straightening</p>
          <p>Crossbites (where the upper teeth bite inside the lower teeth on one or both sides)  </p>
          <p>Overbites (where the upper teeth bite too far over the lower teeth)</p>
          <p>Underbite (where the lower teeth bite too far in front of the upper teeth)</p>
          <p>Open bite (where the front teeth don’t bite together even when the back teeth are closed)</p>
        </b-col>
        <b-col cols="12" md="6">
          <b-img :src="require('@/assets/images/why/patient.jpg')" fluid rounded></b-img>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="section5-wrap">
      <b-container>
      <b-row class="section5" no-gutters>
        <b-col cols="12" md="6">
          <b-img :src="require('@/assets/images/why/scanners.jpg')" fluid></b-img>
        </b-col>
        <b-col cols="12" md="6">
            <h3>We can accept scans from all major intraoral scanners</h3>
            <img class="mr-3" :src="require('@/assets/images/why/3shape.png')" alt="3shape"/>
            <img class="mr-3" :src="require('@/assets/images/why/itero.png')" alt="iTero"/>
            <img class="mr-3" :src="require('@/assets/images/why/dentsply.png')" alt="Dentsply"/>
            <img :src="require('@/assets/images/why/carestrean.png')" alt="Carestrean"/>
            <p>Find us on this account <a href="mailto:Design@deutschealign.com">Design@deutschealign.com</a></p>
            <img class="mr-3" :src="require('@/assets/images/logo.png')" alt="3shape"/>
            <p>The clear German way of teeth straightening</p>
        </b-col>
      </b-row>
      </b-container>
    </b-container>
    <b-container>
      <b-row class="section6 section3">
        <b-col cols="12" md="6" class="wrap-col">
          <div class="wrap">
            <h3>The latest materials for comfort and results</h3>
            <p>With Deutsche Aligner the treatment can be achieved much faster with the movement of 0.25mm per aligner in 7 days.</p>
            <p>Our Aligner material is a unique engineering combination of Elasticity with Rigidity a perfect balance.</p>
            <b-button>Learn More</b-button>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <b-img :src="require('@/assets/images/why/materials.jpg')" fluid></b-img>
        </b-col>
      </b-row>
      <b-row class="section8">
        <b-col cols="12">
          <h3>Talking to your parents? this could help.</h3>
          <p>Please provide some contact details and we’ll send your parent all the info on why  Deutsche Align treatment is right for you.</p>
        </b-col>
        <b-col cols="12" md="4">
          <b-input placeholder="First Name"></b-input>
        </b-col>
        <b-col cols="12" md="4">
          <b-input placeholder="Last Name"></b-input>
        </b-col>
        <b-col cols="12" md="4">
          <b-input placeholder="Age"></b-input>
        </b-col>
        <b-col cols="12" md="4">
          <b-input placeholder="Your Email"></b-input>
        </b-col>
        <b-col cols="12" md="4">
          <b-input placeholder="Phone Number"></b-input>
        </b-col>
        <b-col cols="12" md="4">
          <b-input placeholder="ZIP Code"></b-input>
        </b-col>
        <b-col cols="12">
          <b-button>Send</b-button>
        </b-col>
      </b-row>
    </b-container>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TitleSection from '../components/TitleSection.vue'

export default {
  name: 'WhyDeutsche',
  data () {
    return {
    }
  },
  components: {
    Header,
    Footer,
    TitleSection
  }
}
</script>

<style lang="scss" scoped>
.page-body{
  .title-section{
    padding-top: 70px;
    padding-bottom: 150px;
  }
}
.section1{
  background-color: #fff;
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    color: $bg-primary;
  }
  .nav-link{
    color: $bg-primary;
  }
}
.section2{
  margin-top: 100px;
  text-align: left;
  h2{
    font-size: 42px;
    line-height: 50px;
    max-width: 680px;
    margin: 0 auto;
    margin-bottom: 45px;
  }
  .card-body{
    padding: 40px 30px 30px;
  }
  .yellow{
    border: 1px solid #F3D076;
    background: #FFFBF2;
    h3{
      margin-bottom: 19px;
    }
  }
  .blue{
    border: 1px solid #7E95EA;
    background: #F2F5FF;
    h3{
      margin-bottom: 19px;
    }
  }
  .purple{
    background: #FFF2FD;
    border: 1px solid #C370C0;
    h3{
      margin-bottom: 19px;
    }
  }
}
.section3{
  margin-top: 100px;
  .wrap{
    max-width: 500px;
    h3{
      margin-bottom: 30px;
      font-size: 32px;
      line-height: 38px;
    }
    h4{
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 30px;
    }
    p{
      margin-bottom: 40px;
    }
    .btn{
      background: #EEF1FE;
      border: 1px solid #5577F2;
      color: #5577F2;
    }
  }
}
.section4{
  margin-top: 90px;
  margin-bottom: 40px;
  h3{
    margin-bottom: 20px;
  }
  p{
    padding: 0 35px;
    margin-bottom: 0;
    margin-top: -15px;
  }
  p::before{
      content: '';
      position: relative;
      top: 30px;
      left: -35px;
      padding: 15px;
      width: 15px;
      display: block;
      background-image: url('../assets/images/icons/check.png');
      background-repeat: no-repeat;
  }
}
.section5-wrap{
  background: #FCFCFD;
  .section5{
    padding-top: 75px;
    padding-bottom: 60px;
    h3{
      margin-bottom: 30px;
      font-size: 28px;
      line-height: 40px;
    }
    p{
      margin-bottom: 25px;
      margin-top: 25px;
    }
    a{
      color: $bg-primary;
      text-decoration: underline;
    }
  }
}
.section6{
  margin-top: 75px;
  h3{
    max-width: 328px;
  }
}
.section7{
  .wrap-col{
    .wrap{
      padding: 0 45px;
      h4{
        margin-bottom: 50px;
      }
      h5{
        padding: 0 35px;
        margin-bottom: 10px;
        margin-top: -50px;
      }
      h5::before{
          content: '';
          position: relative;
          top: 30px;
          left: -35px;
          padding: 15px;
          width: 15px;
          display: block;
          background-image: url('../assets/images/icons/check.png');
          background-repeat: no-repeat;
      }
      p{
        max-width: 350px;
      }
    }
  }
}
.section8{
  margin: 70px auto;
  text-align: center;
  background-color: $bg-primary;
  color: $text-secondary;
  border-radius: 10px;
  h3{
    margin-top: 60px;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 30px;
  }
  p{
    max-width: 690px;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .form-control{
    margin-bottom: 20px;
  }
  .btn{
    width: 160px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
@media (min-width:768px) {
  .section3{
    .wrap-col{
      display: flex;
      align-items: center;
      .wrap{
        padding: 45px;
      }
    }
  }
  .section6{
    .wrap-col{
      .wrap{
        padding-left: 0;
      }
    }
  }
  .section7{
    .wrap-col{
      .wrap{
        padding: 0 45px;
      }
    }
  }
}
@media (min-width:992px) {
  .section1{
    max-width: 1030px;
    margin: 0 auto;
    margin-top: -43px;
    border-radius: 8px;
  }
  .section8{
    padding: 0 100px;
  }
}
</style>

<style lang="scss">
.tab-header{
  border-radius: 10px;
  padding-top: 20px;
  background: #FCFCFD;
  box-shadow: 0px 4px 1px 0px #E2E2E240;
  .nav-link{
    padding-bottom: 20px;
    color: $bg-primary;
    opacity: 0.5;
  }
  .nav-link:hover, .nav-link:focus{
    border-color: transparent;
  }
  .nav-link.active, .nav-item.show .nav-link{
    font-size: 18px;
    line-height: 25px;
    border: 1px solid transparent;
    border-bottom: 5px solid $bg-primary;
    opacity: 1;
  }
}
.tab-content.content{
   margin-top: 60px;
  .tab-pane{
    text-align: center;
  }
}
</style>

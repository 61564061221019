<template>
  <div>
    <Header/>
    <title-section
      title="Compare us"
      desc="Still wondering which treatment is right for you? Explore our easy comparison chart below to find out about the advantages of each treatment."
    />
    <b-container class="page-content">
      <div class="section1">
        <b-table-simple>
          <b-tr>
            <td>Made from SmartTrack material</td>
            <td class="text-center primary border-full">clear aligners</td>
            <td class="text-center border-full">other clear aligners</td>
            <td class="text-center border-full">traditional braces</td>
          </b-tr>
          <b-tr>
            <td>In-person consultations with a doctor of your choice</td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check.png')" alt="Check"/>
            </td>
            <td class="border-lr"></td>
            <td class="border-lr"></td>
          </b-tr>
          <b-tr>
            <td>Easily removable for eating, drinking & flossing</td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check.png')" alt="Check"/>
            </td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check-gray.png')" alt="Check"/></td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check-gray.png')" alt="Check"/></td>
          </b-tr>
          <b-tr>
            <td>no emergency visits for broken wires</td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check.png')" alt="Check"/>
            </td>
            <td class="border-lr"></td>
            <td class="border-lr"></td>
          </b-tr>
          <b-tr>
            <td>virtually invisible</td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check.png')" alt="Check"/>
            </td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check-gray.png')" alt="Check"/></td>
            <td class="border-lr"></td>
          </b-tr>
          <b-tr>
            <td>made from traditional brackets and wires</td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check.png')" alt="Check"/>
            </td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check-gray.png')" alt="Check"/></td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check-gray.png')" alt="Check"/></td>
          </b-tr>
          <b-tr>
            <td>each aligner is trimmed to your gum line for optimal comfort and appearance</td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check.png')" alt="Check"/>
            </td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check-gray.png')" alt="Check"/></td>
            <td class="border-lr"></td>
          </b-tr>
          <b-tr>
            <td>blue compliance indicator dots to help you stay on track</td>
            <td class="text-center border-lr">
              <img :src="require('../assets/images/icons/check.png')" alt="Check"/>
            </td>
            <td class="border-lr"></td>
            <td class="border-lr"></td>
          </b-tr>
          <b-tr>
            <td>covered by many orthodontic insurance plans</td>
            <td class="text-center border-lr border-b">
              <img :src="require('../assets/images/icons/check.png')" alt="Check"/>
            </td>
            <td class="text-center border-lr border-b">
              <img :src="require('../assets/images/icons/check-gray.png')" alt="Check"/></td>
            <td class="text-center border-lr border-b">
              <img :src="require('../assets/images/icons/check-gray.png')" alt="Check"/></td>
          </b-tr>
        </b-table-simple>
      </div>
      <start-treatment class="section2"/>
    </b-container>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TitleSection from '../components/TitleSection.vue'
import StartTreatment from '../components/StartTreatment.vue'

export default {
  name: 'CompareUs',
  data () {
    return {
    }
  },
  components: {
    Header,
    Footer,
    TitleSection,
    StartTreatment
  }
}
</script>

<style lang="scss" scoped>
.section1{
  margin-top: 80px;
  tr:nth-child(odd){
    background: #F2F5F9;
  }
  td{
    border: none;
    border-collapse: collapse;
  }
  .primary{
    background-color: $bg-primary;
    color: $text-secondary;
  }
  .border-full{
    border: 1px solid #BBC9FA;
    border-left: 1px solid #BBC9FA;
    border-right: 1px solid #BBC9FA;
  }
  .border-lr{
    border-left: 1px solid #BBC9FA;
    border-right: 1px solid #BBC9FA;
  }
  .border-b{
    border-bottom: 1px solid #BBC9FA;
  }
}
.section2{
  margin-bottom: 110px;
}
</style>

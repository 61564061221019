<template>
  <div class="case-wrapper text-left">
    <h1 class="text-center">New Case</h1>
    <b-form @submit="onSubmit" >
    <b-row>
      <b-col md="6"><b-form-group
        id="input-group-1"
        label="Patient:"
        label-for="input-1"
      >
        <div class="d-flex">
        <b-form-input
          id="input-1"
          v-model="form.firstName"
          type="text"
          placeholder="First name"
          required
          class="mr-2"
        ></b-form-input>
        <b-form-input
          id="input-2"
          v-model="form.lastName"
          type="text"
          placeholder="Last Name"
        ></b-form-input>
        </div>
      </b-form-group>
      </b-col>
      <b-col><b-form-group
        id="input-group-2"
        label="Gender:"
        label-for="input-3"
      >
      <b-form-select v-model="form.gender" :options="genders"></b-form-select>
      </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group
        id="input-group-4"
        label="Age:"
        label-for="input-4"
      >
        <b-form-input
          id="input-4"
          v-model="form.age"
          type="text"
          placeholder="Age"
          required
          class="mr-2"
        ></b-form-input>
      </b-form-group>
      </b-col>
      <b-col>
      <b-form-group
        id="input-group-5"
        label="Impression Type:"
        label-for="input-5"
      >
      <b-form-select v-model="form.impressionType" :options="impressionType"></b-form-select>
      </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center" md="6">
        <b-form-group
        id="input-group-2"
        label="Selected Teeth To Align:"
        label-for="input-3"
        class="text-left mt-3"
      >
        <b-form-input
          id="input-3"
          v-model="selectedTeeths"
          type="text"
          placeholder="Select Teeth from Below"
          plaintext
          class="mr-2"
          ></b-form-input>
        </b-form-group>
    <svg width="320" height="540" id="hove" class="svg-image">
      <tooth height="36" width="36" id="18" x="32" y="191"/>
      <text x="10" y="215" fill="#000">18</text>
      <tooth height="38" width="37" id="17" x="36" y="152"/>
      <text x="12" y="178" fill="#000">17</text>
      <tooth height="42" width="42" id="16"  x="43" y="113"/>
      <text x="22" y="136" fill="#000">16</text>
      <tooth height="35" width="35" id="15"  x="58" y="85"/>
      <text x="35" y="100" fill="#000">15</text>
      <tooth height="32" width="31" id="14"  x="74" y="61"/>
      <text x="54" y="74" fill="#000">14</text>
      <tooth height="28" width="24" id="13"  x="92" y="48"/>
      <text x="77" y="53" fill="#000">13</text>
      <tooth height="28" width="28" id="12"  x="108" y="38"/>
      <text x="105" y="35" fill="#000">12</text>
      <tooth height="30" width="30" id="11"  x="130" y="34"/>
      <text x="135" y="30" fill="#000">11</text>
      <tooth height="30" width="30" id="21"  x="157" y="34"/>
      <text x="165" y="30" fill="#000">21</text>
      <tooth height="29" width="24" id="22"  x="182" y="39"/>
      <text x="191" y="35" fill="#000">22</text>
      <tooth height="24" width="24" id="23"  x="197" y="51"/>
      <text x="212" y="48" fill="#000">23</text>
      <tooth height="28" width="30" id="24"  x="207" y="66"/>
      <text x="238" y="70" fill="#000">24</text>
      <tooth height="32" width="37" id="25"  x="217" y="86"/>
      <text x="257" y="95" fill="#000">25</text>
      <tooth height="43" width="39" id="26"  x="230" y="113"/>
      <text x="270" y="130" fill="#000">26</text>
      <tooth height="43" width="39" id="27"  x="235" y="150"/>
      <text x="285" y="170" fill="#000">27</text>
      <tooth height="41" width="39" id="28"  x="240" y="190"/>
      <text x="290" y="210" fill="#000">28</text>
      <tooth height="41" width="39" id="38"  x="241" y="312"/>
      <text x="290" y="345" fill="#000">38</text>
      <tooth height="39" width="39" id="37"  x="236" y="352"/>
      <text x="283" y="380" fill="#000">37</text>
      <tooth height="41" width="39" id="36"  x="228" y="387"/>
      <text x="270" y="420" fill="#000">36</text>
      <tooth height="35" width="35" id="35"  x="217" y="422"/>
      <text x="255" y="450" fill="#000">35</text>
      <tooth height="32" width="32" id="34"  x="205" y="450"/>
      <text x="240" y="480" fill="#000">34</text>
      <tooth height="35" width="30" id="33"  x="185" y="462"/>
      <text x="210" y="510" fill="#000">33</text>
      <tooth height="32" width="30" id="32"  x="169" y="471"/>
      <text x="181" y="520" fill="#000">32</text>
      <tooth height="38" width="20" id="31"  x="155" y="470"/>
      <text x="156" y="522" fill="#000">31</text>
      <tooth height="38" width="20" id="41"  x="133" y="470"/>
      <text x="133" y="522" fill="#000">41</text>
      <tooth height="38" width="20" id="42"  x="113" y="470"/>
      <text x="108" y="520" fill="#000">42</text>
      <tooth height="32" width="25" id="43"  x="94" y="462"/>
      <text x="83" y="508" fill="#000">43</text>
      <tooth height="30" width="30" id="44"  x="73" y="450"/>
      <text x="55" y="485" fill="#000">44</text>
      <tooth height="31" width="32" id="45"  x="58" y="425"/>
      <text x="35" y="460" fill="#000">45</text>
      <tooth height="41" width="38" id="46"  x="44" y="387"/>
      <text x="20" y="420" fill="#000">46</text>
      <tooth height="38" width="38" id="47"  x="35" y="352"/>
      <text x="10" y="380" fill="#000">47</text>
      <tooth height="38" width="38" id="48"  x="30" y="315"/>
      <text x="8" y="340" fill="#000">48</text>
      <text x="290" y="275" fill="#000" class="horizontal">left</text>
      <text x="5" y="275" fill="#000" class="horizontal">right</text>
      <text x="145" y="140" fill="#000" class="vertical">Top</text>
      <text x="135" y="370" fill="#000" class="vertical">Bottom</text>
      <g class="group" transform="translate(100,-50)"></g>
    </svg>
      </b-col>
      <b-col>
    <b-row>
      <b-col>
      <b-form-checkbox
      id="checkbox-1"
      v-model="form.requestPickup"
      name="checkbox-1"
      value="1"
      unchecked-value="0"
      class="mb-3"
    >
      Request Pickup?
    </b-form-checkbox>
      </b-col>
    </b-row>
      <label class="mt-3">Attachments:</label>
      <b-row>
        <b-col cols="6" class="mb-2">
          <label class="mb-0">File 1</label>
          <attachment-upload v-on:update-filename="form.file1_url = uploadPath + $event" v-on:reset-filename="form.file1_url = ''" />
        </b-col>
        <b-col cols="6" class="mb-2">
           <label class="mb-0">File 2</label>
          <attachment-upload v-on:update-filename="form.file2_url = uploadPath + $event" v-on:reset-filename="form.file2_url = ''" />
        </b-col>
        <b-col cols="6">
           <label class="mb-0">File 3</label>
          <attachment-upload v-on:update-filename="form.file3_url = uploadPath + $event" v-on:reset-filename="form.file3_url = ''" />
        </b-col>
        <b-col cols="6" class="mb-2">
           <label class="mb-0">File 4</label>
          <attachment-upload v-on:update-filename="form.file4_url = uploadPath + $event" v-on:reset-filename="form.file4_url = ''" />
        </b-col>
        <b-col cols="6" class="mb-2">
           <label class="mb-0">File 5</label>
          <attachment-upload v-on:update-filename="form.file5_url = uploadPath + $event" v-on:reset-filename="form.file5_url = ''" />
        </b-col>
        <b-col cols="6" class="mb-2">
           <label class="mb-0">File 6</label>
          <attachment-upload v-on:update-filename="form.file6_url = uploadPath + $event" v-on:reset-filename="form.file6_url = ''" />
        </b-col>
      <b-col md="12" class="mt-3">
    <label for="textarea-notes">Notes:</label>
    <b-form-textarea
        id="textarea-notes"
        placeholder="Notes"
        v-model="form.caseNote"
      ></b-form-textarea>
      </b-col>
      </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-button @click="backToHome()">Back</b-button>
      </b-col>
      <b-col class="text-right">
        <b-button :disabled="disableBtn" type="submit" variant="dark">Create Case</b-button>
      </b-col>
    </b-row>
    </b-form>
  </div>
</template>

<script>

import Tooth from '@/components/Tooth.vue'
import AttachmentUpload from '@/components/AttachmentUpload.vue'
import api from '../api'
import config from '../config'
export default {
  name: 'NewCase',
  data () {
    return {
      disableBtn: false,
      genders: [
        { value: null, text: 'Please select an option' },
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' }
      ],
      impressionType: [
        { value: 'Silicon Rubber', text: 'Silicon Rubber' },
        { value: 'STL', text: 'STL' },
        { value: 'Oral Scan', text: 'Oral Scan' }
      ],
      attachments: [],
      form: {
        firstName: '',
        lastName: '',
        gender: '',
        age: '',
        requestPickup: '0',
        impressionType: '',
        caseNote: '',
        file1_url: '',
        file2_url: '',
        file3_url: '',
        file4_url: '',
        file5_url: '',
        file6_url: ''
      },
      uploadPath: config.filePath
    }
  },
  components: {
    Tooth,
    AttachmentUpload
  },
  computed: {
    selectedTeeths: function () {
      var teethString = ''
      var seperator = ''
      this.$store.state.selectedTeeths.forEach(function (item) {
        teethString += seperator + item
        seperator = ','
      })
      return teethString
    }
  },
  methods: {
    backToHome: function () {
      this.$router.push('/')
    },
    onSubmit (event) {
      event.preventDefault()
      this.createCase()
    },
    onUpload (responses) {
      console.log('file upload result', responses)
      if (responses[0].data) {
        this.attachments.push(responses[0].data)
      }
      console.log('Attachments', this.attachments)
    },
    resetForm: function () {
      this.form.firstName = ''
      this.form.lastName = ''
      this.form.gender = ''
      this.form.age = ''
      this.form.requestPickup = '0'
      this.form.impressionType = ''
      this.form.caseNote = ''
      this.form.file1_url = ''
      this.form.file2_url = ''
      this.form.file3_url = ''
      this.form.file4_url = ''
      this.form.file5_url = ''
      this.form.file6_url = ''
    },
    createCase: function () {
      this.disableBtn = true
      const inputs = {
        'form-data': {
          attachment1: this.form.file1_url,
          attachment2: this.form.file2_url,
          attachment3: this.form.file3_url,
          attachment4: this.form.file4_url,
          attachment5: this.form.file5_url,
          attachment6: this.form.file6_url,
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          age: this.form.age,
          gender: this.form.gender,
          request_pickup: this.form.requestPickup,
          impression_type: this.form.impressionType,
          note: this.form.caseNote,
          selected_teeths: this.selectedTeeths
        }
      }
      console.log('Case Creation input', inputs)
      api
        .request('post', 'GeneralApis/add_case', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            this.disableBtn = false
          } else {
            console.log('Case Creation Response:', data)
            this.$bvToast.toast('New case has been created.', {
              title: 'Case Created!',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            this.resetForm()
            this.$store.commit('CLEAR_TEETH_SELECTION')
            this.$bvModal.hide('modal-new-case')
            this.$emit('refresh-cases')
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 24px;
  margin-top: -40px;
}
.svg-image{
  background: url('../assets/images/tooth/tooth-chart.jpg');
  background-size: 280px 480px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>

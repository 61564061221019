<template>
  <div>
    <Header/>
    <b-container class="section1-wrap" fluid>
      <b-container>
      <b-row class="section1">
        <b-col cols="12" md="6">
          <h1>How does Deutsche® treatment work?</h1>
        </b-col>
        <b-col cols="12" class="image-wrap" md="6">
          <b-img :src="require('@/assets/images/how/section1.jpg')" fluid></b-img>
        </b-col>
      </b-row>
      </b-container>
    </b-container>
    <b-container fluid class="section2-wrap">
      <b-container>
        <b-row class="section2">
          <b-col cols="12" md="6">
            <b-img :src="require('@/assets/images/how/section2.jpg')" fluid></b-img>
          </b-col>
          <b-col cols="12" md="6" class="wrap-col">
            <div class="wrap">
              <h4 class="step">Step -1 </h4>
              <h2>Improve your smile without interrupting your life.</h2>
              <p>Lunch with friends? Popcorn at the movies? The sports you love? With removable clear aligners, you can continue to enjoy your favorite meals AND keep playing the sports you love – no timeouts needed.</p>
            </div>
          </b-col>
        </b-row>
        <div class="section3">
          <h4 class="step">Step -2 </h4>
          <h2>Start transforming your smile right from your first fitting</h2>
          <b-card-group deck>
            <b-card class="yellow">
              <h3>Start wearing your aligners</h3>
              <p>When you pick up your first custom aligners, your doctor will examine your smile, ensuring our high standards are met.</p>
            </b-card>
            <b-card class="blue">
              <h3>Regular doctor check-ins</h3>
              <p>when you pick up your first custom aligners, your doctor will examine your smile, ensuring our high standards are met.</p>
            </b-card>
            <b-card class="purple">
              <h3>Your routine, uninterrupted</h3>
              <p>when you pick up your first custom aligners, your doctor will examine your smile, ensuring our high standards are met.</p>
            </b-card>
          </b-card-group>
        </div>
        <b-row class="section4">
          <b-col cols="12" md="6">
            <b-img :src="require('@/assets/images/how/section3.jpg')" fluid></b-img>
          </b-col>
          <b-col cols="12" md="6" class="wrap-col">
            <div class="wrap">
              <h4 class="step">Step -3</h4>
              <h2>Keep your brand-new smile beautiful</h2>
              <p>Lunch with friends? Popcorn at the movies? The sports you love? With removable clear aligners, you can continue to enjoy your favorite meals AND keep playing the sports you love – no timeouts needed.</p>
              <b-button>Learn About</b-button>
            </div>
          </b-col>
        </b-row>
        <StartTreatement class="mb-5"/>
      </b-container>
    </b-container>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import StartTreatement from '@/components/StartTreatment.vue'
// import StoryTile from '../components/StoryTile.vue'

export default {
  name: 'HowItWork',
  data () {
    return {
    }
  },
  components: {
    Header,
    Footer,
    StartTreatement
  }
}
</script>

    StoryTile
<style lang="scss" scoped>
.step{
  font-size: 24px;
  line-height: 33px;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 40px;
}
h2{
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 35px;
}
.section1-wrap{
   background-color: $bg-primary;
  .section1{
    .image-wrap{
      margin-top: 30px;
    }
    h1{
      padding: 100px 0;
      max-width: 450px;
      color: $text-secondary;
      font-size: 42px;
      line-height: 50px;
    }
  }
}
.section2-wrap{
  margin-top: 100px;
  background-color: $text-secondary;
  .section2{
    background-color: $text-secondary;
    h2{
      max-width: 680px;
      margin: 0 auto;
    }
  }
}
.section3{
  text-align: center;
  margin-top: 30px;
  h2{
    max-width: 540px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  .card-body{
    padding: 50px 40px 40px;
  }
  .yellow{
    border: 1px solid #F3D076;
    background: #FFFBF2;
    h3{
      margin-bottom: 19px;
    }
  }
  .blue{
    border: 1px solid #7E95EA;
    background: #F2F5FF;
    h3{
      margin-bottom: 19px;
    }
  }
  .purple{
    background: #FFF2FD;
    border: 1px solid #C370C0;
    h3{
      margin-bottom: 19px;
    }
  }
}
.section4{
  margin-top: 100px;
  .wrap{
    max-width: 500px;
    p{
      margin-bottom: 40px;
    }
    .btn{
      background: #EEF1FE;
      border: 1px solid #5577F2;
      color: #5577F2;
    }
  }
}
.section5{
  margin-top: 100px;
  margin-bottom: 100px;
  h2{
    margin: 0 auto;
    max-width: 485px;
    margin-bottom: 0px;
    text-align: center;
  }
}
@media (min-width:768px) {
}
@media (min-width:992px) {
  .step{
    margin-top: 0;
  }
  .section1-wrap{
    .section1{
      .image-wrap{
        margin-top: 30px;
      }
      h1{
      }
    }
  }
  .section2-wrap{
    margin-top: -100px;
    background-color: $text-secondary;
    .section2{
      padding-top: 200px;
      background-color: $text-secondary;
      h2{
        font-size: 42px;
        line-height: 50px;
        margin-bottom: 45px;
      }
      .wrap-col{
        display: flex;
        align-items: center;
        .wrap{
          padding: 45px;
        }
      }
    }
  }
}
</style>

<template>
  <b-tr>
    <b-td colspan="3">
      <b-form-select v-model="productId" @change="getProductCharges()">
        <b-form-select-option v-for="prod in productList"
          :key="prod[0]" :value="prod[0]">{{prod[1]}}</b-form-select-option>
      </b-form-select>
    </b-td>
    <b-td colspan="1">
      <b-form-input v-model="quantity" type="number" @change="updateTotal()"></b-form-input>
    </b-td>
    <b-td colspan="2">
      <b-input-group size="sm" :prepend="currency">
        <b-form-input class="ml-2" v-model="price" plaintext></b-form-input>
      </b-input-group>
    </b-td>
    <b-td colspan="2">
      <b-input-group size="sm" :prepend="currency">
        <b-form-input class="ml-2" v-model="subTotal" plaintext></b-form-input>
      </b-input-group>
    </b-td>
    <b-td class="icon-wrap">
      <b-icon-trash variant="danger" @click="removeRow()"/>
    </b-td>
  </b-tr>
</template>

<script>
import api from '../api'
import { BIconTrash } from 'bootstrap-vue'
export default {
  name: 'ProductLine',
  data () {
    return {
      productId: this.product.product_id,
      quantity: this.product.quantity,
      price: this.product.price,
      subTotal: this.product.sub_total,
      currency: ''
    }
  },
  components: {
    BIconTrash
  },
  props: {
    product: Object,
    productList: Array,
    partnerId: Array,
    index: Number
  },
  computed: {
    getLink: function () {
      if (this.isActive) {
        return 'tooth' + this.id + 'h.png'
      } else {
        return 'tooth' + this.id + '.png'
      }
    }
  },
  methods: {
    removeRow: function () {
      this.$emit('remove-row', this.product)
    },
    updateTotal: function (value) {
      console.log('updateTotal', this.subTotal)
      this.subTotal = this.price * this.quantity
      console.log('updateTotal', this.subTotal)
      this.$emit('update-total', { product_id: this.productId, quantity: this.quantity, sub_total: this.subTotal, key: this.index })
    },
    getProductCharges: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'get_sale_price_data',
          'args': { 'partner_id': this.partnerId[0], 'product_id': this.productId }
        },
        'modecode': 100
      }
      console.log('lab charges input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('sale order list:', data.result)
            // this.labCharges = data.result[0]
            this.price = data.result[0].unit_price
            this.currency = data.result[0].currency_id[0][1].toString()
            this.quantity = 1
            this.updateTotal()
            setTimeout(function () {
            }, 500)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
image {
    cursor: pointer;
}
table td.icon-wrap{
  vertical-align: middle;
  cursor: pointer;
}
</style>

<template>
  <b-tr>
    <b-td colspan="3">
      <b-form-select v-model="productId" @change="getLabCharges()">
        <b-form-select-option v-for="prod in productList"
          :key="prod.product_id" :value="prod.product_id">{{prod.product_name}}</b-form-select-option>
      </b-form-select>
    </b-td>
    <b-td colspan="2">{{currency + ' ' + price}}
    </b-td>
    <b-td colspan="1">
      <b-form-input v-model="discountPercent" type="number" @change="updateDiscount()"></b-form-input>
    </b-td>
    <b-td colspan="1">
      <b-form-input v-model="discount" type="number" @change="updatePercent()"></b-form-input>
    </b-td>
    <b-td colspan="2">{{currency + ' ' + subTotal}}
    </b-td>
    <b-td class="icon-wrap">
      <b-icon-trash variant="danger" @click="removeRow()"/>
    </b-td>
  </b-tr>
</template>

<script>
import api from '../api'
import { BIconTrash } from 'bootstrap-vue'
export default {
  name: 'LabItem',
  data () {
    return {
      productId: this.product.product_id,
      discount: this.product.discount,
      discountPercent: this.product.discP,
      price: this.product.price,
      subTotal: this.product.net_price,
      currency: ''
    }
  },
  components: {
    BIconTrash
  },
  props: {
    product: Object,
    productList: Array,
    index: Number,
    userId: Number
  },
  computed: {
  },
  methods: {
    removeRow: function () {
      this.$emit('remove-row', this.product)
    },
    updatePercent: function () {
      if (this.price) {
        this.discountPercent = this.discount / this.price * 100
        this.updateTotal()
      }
    },
    updateDiscount: function () {
      if (this.price) {
        this.discount = this.discountPercent ? (this.price * this.discountPercent / 100) : 0
        this.updateTotal()
      }
    },
    updateTotal: function () {
      this.subTotal = this.price - this.discount
      console.log('updateTotal', this.subTotal)
      this.$emit('update-total', { product_id: this.productId, discount: this.discount, discount_percent: this.discountPercent, currency: this.currency, sub_total: this.subTotal, price: this.price, key: this.index })
    },
    getLabCharges: function () {
      const inputs = {
        filter: {
          'user_id': this.userId,
          'product_id': this.productId
        }
      }
      console.log('lab charges input', inputs)
      api
        .request('post', 'PortalApis/get_lab_charge', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          var labCharges = { price: 0, currency: 'AED' }
          if (data.productList) {
            console.log('Lab charge list:', data.productList)
            labCharges = data.productList[0]
          }
          this.price = labCharges.price
          this.currency = labCharges.currency
          this.updateTotal()
          setTimeout(function () {
          }, 500)
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
image {
    cursor: pointer;
}
table td.icon-wrap{
  vertical-align: middle;
  cursor: pointer;
}
</style>

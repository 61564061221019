<template>
    <VueFileAgent
      ref="fileRef"
      :thumbnailSize="120"
      :theme="'list'"
      :multiple="false"
      :deletable="true"
      :uploadUrl="uploadUrl"
      :meta="true"
      :compact="true"
      :accept="'image/*,.zip,.stl'"
      :maxSize="'10MB'"
      :helpText="'Choose images, zip or stl files'"
      :errorText="{
        type: 'Invalid file type. Only images, stl or zip Allowed',
        size: 'Files should not exceed 10MB in size',
      }"
      @upload="onUpload($event)"
      @upload:update="onUpload($event)"
      @delete="onDelete($event)"
      v-model="uploadFile"
    >
    </VueFileAgent>
</template>

<script>
import config from '../config'
export default {
  name: 'AttachmentUpload',
  props: {
    fileName: String,
    label: String,
    helpText: String,
    errorText: String,
    fileExt: String,
    selectLabel: {
      type: String,
      default: 'Select stl file'
    }
  },
  data () {
    return {
      uploadFile: null,
      uploaded: false,
      uploadUrl: config.serverURI + 'GeneralApis/upload_attachment',
      uploadHeaders: { }
    }
  },
  computed: {
  },
  methods: {
    cleanFileName: function (fileName) {
      const stripedFileName = fileName.replace(/\s+/g, '-')
      return stripedFileName.replace(/[^-a-zA-Z0-9]+/g, '')
    },
    remove: function () {
      var uploadFile = this.uploadFile
      var uploadData = { caseid: this.cleanFileName(this.uploadFile.name()) }
      this.$refs.fileRef.deleteUpload(this.uploadUrl, this.uploadHeaders, [uploadFile], uploadData)
      this.uploadFile = null
      this.uploaded = false
    },
    upload: function () {
      console.log('Upload method')
      var self = this
      this.$refs.fileRef.upload(this.uploadUrl, this.uploadHeaders, [this.uploadFile], function createFormData (fileData) {
        var formData = new FormData()
        formData.append('id', self.cleanFileName(self.uploadFile.name().split('.').slice(0, -1).join('.')))
        formData.append('file', fileData.file)
        formData.append('filename_ext', self.uploadFile.ext)
        const escapedFileName = self.$store.state.activeCase.name.replace(/\s+/g, '-')
        formData.append('file_path', self.cleanFileName(escapedFileName.replace('/', '-')))
        return formData
      }).then(function () {
        self.uploaded = true
        // self.$emit('update-filename')
        setTimeout(function () {
          // self.uploadFile.progress(0);
        }, 500)
      })
    },
    onUpload (responses) {
      console.log('file upload result', responses)
      if (responses[0].data) {
        console.log('onUpload File name: ', responses[0].data.filename.substring(1))
        // this.$emit('update-filename', this.cleanFileName(responses[0].data.filename.substring(1)))
        console.log('file name:', responses[0].data.filename)
        const filePath = responses[0].data.filename.split('.').slice(1, 3).join('.')
        // const fileName = this.cleanFileName(this.uploadFile.name().split('.').slice(0, -1).join('.'))
        const fileName = responses[0].data.upload_data.file_name
        const fileParts = filePath.substring(0, filePath.indexOf(this.uploadFile.name()))
        console.log('File Path: ', fileParts)
        console.log('File Name: ', fileName)
        const fullPath = fileParts + fileName
        console.log('Full Path: ', fullPath)
        this.$emit('update-filename', fullPath)
      }
    },
    onDelete (responses) {
      console.log('file delete result', responses)
      this.$emit('reset-filename')
    }
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>

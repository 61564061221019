<template>
  <b-container fluid class="footer">
    <b-container class="footer-main">
      <b-row>
        <b-col cols="12" md="3">
          <img src="@/assets/images/logo-light.png" alt="Deutsche">
          <p class="tagline">The Clear German way of<br/>teeth straightening</p>
          <b-link class="phone" href="tel:+97143992339">+97143992339</b-link>
          <b-list-group horizontal class="social-links">
            <b-list-group-item class="border-0"><b-link href=""><img height="18" src="@/assets/images/social/insta-icon.png" alt="instagram"></b-link></b-list-group-item>
            <b-list-group-item class="border-0"><b-link href=""><img height="18" src="@/assets/images/social/fb-icon.png" alt="facebook"></b-link></b-list-group-item>
            <b-list-group-item class="border-0"><b-link href=""><img height="18" src="@/assets/images/social/in-icon.png" alt="linkedin"></b-link></b-list-group-item>
            <b-list-group-item class="border-0"><b-link href=""><img height="18" src="@/assets/images/social/youtube-icon.png" alt="youtube"></b-link></b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-nav vertical class="footer-nav">
            <b-nav-text class="footer-section-head">QUICK LINKS</b-nav-text>
            <b-nav-item :to="{ name: 'WhyDeutsche' }">Why Deutsche Align</b-nav-item>
            <b-nav-item :to="{ name: 'Result' }">Results</b-nav-item>
            <b-nav-item :to="{ name: 'FindDentist' }">Find a dentist</b-nav-item>
          </b-nav>
        </b-col>
        <b-col cols="12" md="3">
          <b-nav vertical class="footer-nav">
            <b-nav-text class="footer-section-head">LEGAL</b-nav-text>
            <b-nav-item>Terms of service</b-nav-item>
            <b-nav-item>Privacy policy</b-nav-item>
          </b-nav>
        </b-col>
        <b-col cols="12" md="3">
          <b-nav vertical class="footer-nav">
            <b-nav-text class="footer-section-head">Contact</b-nav-text>
            <b-nav-item>+971545598255</b-nav-item>
            <b-nav-item>+971544220077</b-nav-item>
            <b-nav-item>info@deutschealign.com</b-nav-item>
            <b-nav-text>UAE, Dubai, JLT, Cluster F,</b-nav-text>
            <b-nav-text>Indigo Icon Tower 27th floor</b-nav-text>
          </b-nav>
        </b-col>
      </b-row>
      <b-row no-gutters class="footer-bottom">
        <b-col md="6">
          <b-nav-text>© Deutsche.com 2021</b-nav-text>
        </b-col>
        <b-col md="6">
          <b-nav id="bottom-nav" class="ml-auto">
            <b-nav-item>Privacy Policy</b-nav-item>
            <b-nav-item>Terms</b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>

export default {
  name: 'Footer',
  components: {
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.footer{
  background-color: $bg-primary;
  color: $text-secondary;
  text-align: left;
}
.footer-main{
  padding-top:65px;
}
.tagline{
  margin-top: 24px;
  margin-bottom: 19px;
}
.phone{
  text-decoration: none;
  color: $text-secondary;
}
.phone::before{
  content: '';
  position: relative;
  top: 12px;
  padding: 12px;
  width: 12px;
  background-image: url('../assets/images/social/phone.png');
  background-repeat: no-repeat;
}
.social-links{
  width: 140px;
}
.social-links .list-group-item{
  background-color: $bg-primary;
  padding-left: 0;
  padding-right: 20px;
}
.footer-nav.nav .nav-link{
  color: $text-secondary;
  font-size: 16px;
}
.footer-section-head{
  font-family: 'Airbnb Medium';
  font-size: 18px;
  text-transform: uppercase;
  padding-left: 1rem;
}
.footer-nav .navbar-text{
  padding-left: 1rem;
}
.footer-bottom{
  border-top: 1px solid #3E4650;
  margin-top: 40px;
  padding-top: 5px;
  padding-bottom: 20px;
  font-size: 14px;
}
#bottom-nav{
  float: right;
}
#bottom-nav .nav-link{
  color: $text-secondary;
}
</style>

<template>
  <b-container class="header">
    <b-navbar toggleable="sm" variant="faded" type="light">
      <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
      <b-navbar-brand :to="{ name: 'Home' }">
        <img src="@/assets/images/logo.png" alt="Deutsche">
      </b-navbar-brand><b-collapse id="nav-text-collapse" is-nav>
      <b-navbar-nav id="header-nav" class="ml-auto">
        <b-nav-item :to="{ name: 'WhyDeutsche' }">Why Deutsche</b-nav-item>
        <b-nav-item :to="{ name: 'HowItWork' }">How it works</b-nav-item>
        <b-nav-item :to="{ name: 'CompareUs' }">Compare us</b-nav-item>
        <b-nav-item :to="{ name: 'ContactUs' }">Contact us</b-nav-item>
        <b-nav-item :to="{ name: 'Result' }">Results</b-nav-item>
        <b-nav-form class="ml-sm-5">
          <b-button size="sm" variant="primary" class="my-2 my-sm-0 ml-sm-5" :to="{ name: 'DentistLogin' }">Dentist Login</b-button>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.header{
  margin-bottom: 15px;
}
.navbar-light #header-nav.navbar-nav .nav-link{
  color: #59636C;
  font-size: 16px;
}
.navbar-light #header-nav.navbar-nav .nav-link:hover{
  color: $bg-primary;/* #303C47;*/
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus{
  outline: none;
}
</style>

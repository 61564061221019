<template>
  <b-overlay :show="waitingResponse">
    <b-tabs id="case-list" pills vertical nav-wrapper-class="left-col" v-model="tabIndex">
      <b-tab @click="getPendingUpload()">
        <template #title>
          <span v-if="uploadUser">Pending Upload <b-badge v-if="newCaseCount" :variant="tabIndex ? 'dark': 'secondary'">{{newCaseCount}}</b-badge></span>
          <span v-if="!uploadUser">New Cases <b-badge v-if="newCaseCount" :variant="tabIndex ? 'dark': 'secondary'">{{newCaseCount}}</b-badge></span>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="On Hold" v-if="uploadUser" @click="getOnHold()">
        <template #title>
          On Hold <b-badge v-if="onHoldCount" :variant="tabIndex === 1 ? 'dark': 'secondary'">{{onHoldCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="Revision Requests" v-if="uploadUser" @click="getRevisionRequests()">
        <template #title>
          Revision Requests <b-badge v-if="pendingRevisionCount" :variant="tabIndex !== 2 ? 'dark': 'secondary'">{{pendingRevisionCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="Pending Approval" v-if="uploadUser" @click="getAllApprovalCases()">
        <template #title>
          Pending Approval <b-badge v-if="pendingApprovalCount" :variant="tabIndex !== 3 ? 'dark': 'secondary'">{{pendingApprovalCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredRevisions"
            :key="order.id">
            <case :item="order" isRevision v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="Pending Approval" v-if="!uploadUser" @click="getAllApprovalCases()">
        <template #title>
          Pending Approval <b-badge v-if="pendingApprovalCount" :variant="tabIndex !== 1 ? 'dark': 'secondary'">{{pendingApprovalCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredRevisions"
            :key="order.id">
            <case :item="order" isRevision v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="Pending Revision" v-if="!uploadUser" @click="getRevisionRequests()">
        <template #title>
          Pending Revision <b-badge v-if="pendingRevisionCount" :variant="tabIndex === 2 ? 'secondary': 'dark'">{{pendingRevisionCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="Pending Completion" @click="getPendingCompletion">
        <template #title>
          Pending Completion <b-badge v-if="pendingCompletionCount" :variant="tabIndex === 3 ? 'secondary': 'dark'">{{pendingCompletionCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="Printing Done" v-if="uploadUser" @click="getPrintingDone()">
        <template #title>
          Printing Done <b-badge v-if="printingDoneCount" :variant="tabIndex === 5 ? 'secondary': 'dark'">{{printingDoneCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="Completed Cases" @click="getCompletedCases()">
        <template #title>
          Completed Cases <b-badge v-if="completedCaseCount" :variant="(uploadUser && tabIndex === 6) || (!uploadUser && tabIndex ===4) ? 'secondary': 'dark'">{{completedCaseCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab> <!--
      <b-tab title="Messages To Reply" v-if="uploadUser" @click="getMessagesToReply()">
        <template #title>
          Messages To Reply <b-badge variant="info">{{caseCount && caseCount.messages_to_replay}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.id">
            <case :item="order" v-on:show-details="getCaseDetails" v-on:show-message="showModal" isMessage/>
          </b-col>
        </b-card-group>
      </b-tab>
      <b-tab title="Messages" v-if="!uploadUser" @click="getMessagesToReply()">
        <template #title>
          Messages <b-badge variant="info">{{caseCount && caseCount.messages_to_replay}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.id">
            <case :item="order" v-on:show-details="getCaseDetails" v-on:show-message="showModal" isMessage/>
          </b-col>
        </b-card-group>
      </b-tab> -->
      <b-tab title="Cancelled Cases" v-if="cancelledCaseCount" @click="getCancelledCases()">
        <template #title>
          Cancelled Cases <b-badge  :variant="(uploadUser && tabIndex === 7) || (!uploadUser && tabIndex === 5) ? 'secondary': 'dark'">{{cancelledCaseCount}}</b-badge>
        </template>
        <b-card-group deck>
          <b-col md="3" class="mb-5 text-center"
            v-for="order in filteredCases"
            :key="order.case_id">
            <case :item="order" v-on:show-details="getCaseDetails"/>
          </b-col>
        </b-card-group>
      </b-tab>
    </b-tabs>
    <b-modal
      id="modal-case-details"
      size="xl"
      footer-class="border-0 p-0"
      header-class="border-0"
    >
      <template #modal-header="{}">
      </template>
      <template #modal-footer>
        <div class="p-0"></div>
      </template>
      <case-details
        :item="activeCase"
        v-on:back-to-cases="closeCaseDetails"
        v-on:refresh-cases="refreshList()"
        v-on:update-case="updateCase"
        v-on:reload-case="getOrderDetails"
        v-on:show-message="showModal"
        :tabIndex="tabIndex"
        :newCase="!tabIndex"/>
    </b-modal>
    <b-modal
      id="modal-mark-read"
      title="Are you sure to mark the communication as read?"
      footer-class="border-0"
      header-class="border-0"
      @ok="markAsRead"
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="info" @click="ok()">
          Yes
        </b-button>
        <b-button size="sm" variant="dark" @click="cancel()">
          No
        </b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import api from '../api'
import Case from '@/components/Case.vue'
import CaseDetails from '@/components/CaseDetails.vue'

export default {
  name: 'CaseList',
  props: {
    saleOrders: []
  },
  data () {
    return {
      tabIndex: 0,
      activeCase: {},
      activeStatus: '10',
      // activeTabCases: [],
      pendingUpload: [],
      onHold: [],
      revisionRequests: null,
      completedCases: null,
      pendingCompletion: null,
      pendingApproval: null,
      revisionApprovals: null,
      printingDone: null,
      messagesToReply: null,
      cancelledCases: null,
      fileRecords: [],
      uploadUrl: 'http://localhost/api_middleware/api/App_apis/upload',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [], // maintain an upload queue
      waitingResponse: false,
      caseCount: {
        'new_cases': 5,
        'pending_revision': 3
      },
      activeCaseId: null
    }
  },
  components: {
    Case,
    CaseDetails
  },
  computed: {
    newCaseCount: function () {
      return this.saleOrders.reduce((acc, obj) => obj.case_status === '10' ? ++acc : acc, 0)
    },
    pendingApprovalCount: function () {
      return this.saleOrders.reduce((acc, obj) => obj.case_status === '50' ? ++acc : acc, 0)
    },
    pendingRevisionCount: function () {
      return this.saleOrders.reduce((acc, obj) => obj.case_status === '40' ? ++acc : acc, 0)
    },
    pendingCompletionCount: function () {
      return this.saleOrders.reduce((acc, obj) => obj.case_status === '60' ? ++acc : acc, 0)
    },
    printingDoneCount: function () {
      return this.saleOrders.reduce((acc, obj) => obj.case_status === '70' ? ++acc : acc, 0)
    },
    completedCaseCount: function () {
      return this.saleOrders.reduce((acc, obj) => obj.case_status === '90' ? ++acc : acc, 0)
    },
    cancelledCaseCount: function () {
      return this.saleOrders.reduce((acc, obj) => obj.case_status === '100' ? ++acc : acc, 0)
    },
    activeTabCases: function () {
      return this.saleOrders.filter(obj => obj.case_status === this.activeStatus)
    },
    newCases: function () {
      return this.saleOrders.filter(obj => obj.case_status === '10')
    },
    pendingApprovals: function () {
      return this.saleOrders.filter(obj => obj.case_status === '50')
    },
    pendingRevisions: function () {
      return this.saleOrders.filter(obj => obj.case_status === '40')
    },
    pendingCompletions: function () {
      return this.saleOrders.filter(obj => obj.case_status === '60')
    },
    showCaseDetails: function () {
      return this.$store.state.showCaseDetails
    },
    uploadUser: function () {
      return this.$store.state.user.user_role === '12'
    },
    getTitle: function () {
      if (this.$store.state.showCaseDetails) {
        return 'Case Details'
      } else if (this.$store.state.user.upload_clearsmile_file) {
        return 'Cases Pending For Files Upload'
      } else {
        return 'My Cases'
      }
    },
    filteredCases: function () {
      let tempCases = this.activeTabCases
      switch (this.selectedFilter) {
        case 'Case#':
          if (this.filterText !== '') {
            tempCases = tempCases.filter((item) => {
              return item.name
                .includes(this.filterText)
            })
          }
          break
        case 'Patient':
          if (this.filterText !== '') {
            tempCases = tempCases.filter((item) => {
              return item.patient_id[0][1]
                .toUpperCase()
                .includes(this.filterText.toUpperCase())
            })
          }
          break
        case 'Dentist':
          if (this.$store.state.user.upload_clearsmile_file && this.filterText !== '') {
            tempCases = tempCases.filter((item) => {
              return item.dentist_id[0][1]
                .toUpperCase()
                .includes(this.filterText.toUpperCase())
            })
          }
          break
        case 'Clinic':
          if (this.$store.state.user.upload_clearsmile_file && this.filterText !== '') {
            tempCases = tempCases.filter((item) => {
              return item.partner_id[0][1]
                .toUpperCase()
                .includes(this.filterText.toUpperCase())
            })
          }
          break
      }/*
      if (this.filter.caseNo !== '') {
        tempCases = tempCases.filter((item) => {
          return item.name
            .includes(this.filter.caseNo)
        })
      }
      if (this.filter.patient !== '') {
        tempCases = tempCases.filter((item) => {
          return item.patient_id[0][1]
            .toUpperCase()
            .includes(this.filter.patient.toUpperCase())
        })
      }
      if (this.$store.state.user.upload_clearsmile_file && this.filter.dentist !== '') {
        tempCases = tempCases.filter((item) => {
          return item.dentist_id[0][1]
            .toUpperCase()
            .includes(this.filter.dentist.toUpperCase())
        })
      }
      if (this.$store.state.user.upload_clearsmile_file && this.filter.clinic !== '') {
        tempCases = tempCases.filter((item) => {
          return item.partner_id[0][1]
            .toUpperCase()
            .includes(this.filter.clinic.toUpperCase())
        })
      } */
      return tempCases
    },
    filteredRevisions: function () {
      let tempCases = this.revisionApprovals ? this.revisionApprovals : []
      switch (this.selectedFilter) {
        case 'Case#':
          if (this.filterText !== '') {
            tempCases = tempCases.filter((item) => {
              return item.name
                .includes(this.filterText)
            })
          }
          break
        case 'Patient':
          if (this.filterText !== '') {
            tempCases = tempCases.filter((item) => {
              return item.patient_id[0][1]
                .toUpperCase()
                .includes(this.filterText.toUpperCase())
            })
          }
          break
        case 'Dentist':
          if (this.$store.state.user.upload_clearsmile_file && this.filterText !== '') {
            tempCases = tempCases.filter((item) => {
              return item.dentist_id[0][1]
                .toUpperCase()
                .includes(this.filterText.toUpperCase())
            })
          }
          break
        case 'Clinic':
          if (this.$store.state.user.upload_clearsmile_file && this.filterText !== '') {
            tempCases = tempCases.filter((item) => {
              return item.partner_id[0][1]
                .toUpperCase()
                .includes(this.filterText.toUpperCase())
            })
          }
          break
      }
      return tempCases
    }
  },
  methods: {
    getAllApprovalCases: function () {
      // this.activeTabCases = this.pendingApprovals
      this.activeStatus = '50'
    },
    refreshCases: function () {
      if (this.uploadUser) {
        switch (this.tabIndex) {
          case 0: this.getPendingUpload()
            break
          case 1: this.getOnHold()
            break
          case 2: this.getRevisionRequests()
            break
          case 3: this.revisonApprovals()
            this.getPendingApproval()
            break
          case 4: this.getPendingCompletion()
            break
          case 5: this.getPrintingDone()
            break
          case 6: this.getCompletedCases()
            break
          case 7: this.getMessagesToReply()
            break
        }
      } else {
        switch (this.tabIndex) {
          case 0: this.getPendingUpload()
            break
          case 1: this.revisonApprovals()
            this.getPendingApproval()
            break
          // case 2: this.revisonApprovals()
          // break
          case 2: this.pendingRevision()
            break
          case 3: this.getPendingCompletion()
            break
          case 4: this.getCompletedCases()
            break
          case 5: this.getMessagesToReply()
            break
          case 6: this.getCancelledCases()
            break
        }
      }
      console.log('Tab Index', this.tabIndex)
    },
    newCase: function () {
      this.$router.push({ path: '/new-case' })
    },
    refineCase: function () {
      this.$router.push({ path: '/refinement' })
    },
    updateCase: function (item) {
      this.activeCase = item
    },
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload)
      this.fileRecordsForUpload = []
    },
    getCaseDetails: function (item) {
      this.activeCase = item
      // this.$store.commit('SET_SHOW_CASE_DETAILS', true)
      this.$store.commit('SET_ACTIVE_CASE', item)
      this.$bvModal.show('modal-case-details')
    },
    closeCaseDetails: function (index) {
      this.$store.commit('GO_HOME')
      this.tabIndex = index
    },
    getPendingUpload: function () {
      this.activeStatus = '10'
    },
    getOnHold: function () {
      this.activeStatus = '30'
    },
    getPrintingDone: function () {
      this.activeStatus = '70'
    },
    getMessagesToReply: function () {
      this.waitingResponse = true
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'replay_message',
          'args': '[]'
        },
        'modecode': 100
      }
      console.log('Messages to Reply Sale Orders Input', inputs)
      api
        .request('post', 'get', inputs)
        .then((response) => {
          this.waitingResponse = false
          console.log('Messages to reply Sale Orders API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('sale order list:', data.result)
            if (typeof data.result[0] && typeof data.result[0] === 'object') {
              this.messagesToReply = data.result
            }
            // this.activeTabCases = this.messagesToReply
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    revisonApprovals: function () {
      this.waitingResponse = true
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'get_revision_approvals',
          'args': '[]'
        },
        'modecode': 100
      }
      console.log('Revision Approvals', inputs)
      api
        .request('post', 'get', inputs)
        .then((response) => {
          this.waitingResponse = false
          console.log('Revision Approval API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('sale order list:', data.result)
            if (typeof data.result[0] && typeof data.result[0] === 'object') {
              this.revisionApprovals = data.result
            }
            // this.activeTabCases = this.revisionApprovals
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    pendingRevision: function () {
      this.activeStatus = '40'
    },
    getRevisionRequests: function () {
      this.activeStatus = '40'
    },
    getCompletedCases: function () {
      this.activeStatus = '90'
    },
    getPendingCompletion: function () {
      this.activeStatus = '60'
    },
    getPendingApproval: function () {
      this.activeStatus = '50'
    },
    getCancelledCases: function () {
      this.activeStatus = '100'
    },
    getCasesCount: function () {
      this.waitingResponse = true
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'get_clearsmile_dashboard',
          'args': '[]'
        },
        'modecode': 100
      }
      console.log('Cases Count', inputs)
      api
        .request('post', 'get', inputs)
        .then((response) => {
          this.waitingResponse = false
          console.log('Cases count API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Case Count:', data.result)
            if (typeof data.result[0] && typeof data.result[0] === 'object') {
              this.caseCount = data.result[0]
            }
            // this.activeTabCases = this.pendingApproval
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    getOrderDetails: function (orderId) {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'get_sale_order_details',
          'args': { 'sale_order_id': orderId }
        },
        'modecode': 100
      }
      console.log('sale order details input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            // this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('sale order details:', data.result)
            this.getCaseDetails(data.result[0])
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    refreshList: function () {
      this.$emit('refresh-cases')
    },
    showModal: function (id) {
      this.activeCaseId = id
      this.$bvModal.show('modal-mark-read')
    },
    markAsRead: function () {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'close_communication',
          'args': { 'sale_order_id': this.activeCaseId }
        },
        'modecode': 100
      }
      console.log('Mark as read input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Mark as read data:', data.result)
            this.refreshList()
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    }
  },
  mounted () {
    // this.activeTabCases = this.newCases
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  font-size: 24px;
}
.tab-list{
  min-height: 300px;
}
</style>
<style lang="scss">
#case-list{
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    background-color: $bg-primary;
    color: $text-secondary;
  }
  .nav-pills .nav-link{
    color: $bg-primary;
    border-radius: 10px;
  }
  .badge{
    border-radius: 50%;
    padding: 5px 7px 3px 7px;
    margin-left: 15px;
  }
  .badge-secondary{
    background-color: $text-secondary;
    color: $bg-primary;
  }
  .tab-content{
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  #case-list{
    display: flex;
    .left-col{
      width: 250px;
      margin-top: -50px;
      border-right: 1px solid #EBEBEB;
    }
  }
}
</style>

<template>
  <b-col>
    <b-card-group deck>
      <b-card class="certified">
        <b-img :src="require('@/assets/images/home/certified-dentist.png')"></b-img>
        <h3>DA certified dentist</h3>
        <p>Use our search to find a Deutsche dentist in your area</p>
        <b-button variant="primary">Search Now</b-button>
      </b-card>
      <b-card class="suitable">
        <b-img :src="require('@/assets/images/home/suitable.png')"></b-img>
        <h3>Am I suitable?</h3>
        <p>Use our search to find a Deutsche dentist in your area</p>
        <b-button>Am I Suitable</b-button>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
export default {
  name: 'DentistSearchTiles',
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.card-deck{
    justify-content: center;
  }
  .card{
    max-width: 470px;
    color: $text-secondary;
    border-radius: 10px;
  }
  .card-body{
    max-width: 300px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  h3{
    margin-top: 35px;
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 39px;
  }
  p{
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .certified{
    background-color: $bg-primary;
    .btn-primary{
      background-color: #5577F2;
    }
  }
  .suitable{
    background: #5577F2;
  }
</style>

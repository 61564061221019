<template>
  <div class="profile-drop-down">
    <b-dropdown right>
      <!-- Using 'button-content' slot -->
      <template #button-content>
        <div class="btn-wrap">
          <img class="mr-2" :src="require('@/assets/images/icons/user.png')"/>
          <div class="name-wrap">
            <p class="profile-name">{{userName}}</p>
            <p class="profile-role">{{userRole}}</p>
          </div>
          <img class="down-icon" :src="require('@/assets/images/icons/chevron-down.png')"/>
        </div>
      </template>
      <!--<b-dropdown-item href="#">Profile</b-dropdown-item>-->
      <b-dropdown-item v-b-modal.modal-change-password>Change Password</b-dropdown-item>
      <b-dropdown-item @click="signOut()">Sign Out</b-dropdown-item>
    </b-dropdown>
    <b-modal
      id="modal-change-password"
      title="Change Password"
      footer-class="border-0"
      header-class="border-0"
      @ok="changePassword"
    >
        <b-form-input
          placeholder="Pasword"
          type="password"
          :state="passwordState"
          v-model="userPassword"
          class="mb-3 input-login"
        ></b-form-input>
        <b-form-input
          type="password"
          placeholder="Confirm Password"
          :state="passwordConfirmState"
          v-model="confirmPassword"
          class="mb-3 input-login"
        ></b-form-input>
      <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="info" @click="ok()" :disabled="changeButtonState">
        Submit
      </b-button>
      <b-button size="sm" variant="dark" @click="cancel()">
        Cancel
      </b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
export default {
  name: 'ProfileDropDown',
  props: [],
  data () {
    return {
      userPassword: '',
      confirmPassword: '',
      waitingResponse: false
    }
  },
  computed: {
    userName: function () {
      return this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name
    },
    userRole: function () {
      switch (this.$store.state.user && this.$store.state.user.user_role) {
        case '11': return 'Admin Account'
        case '12': return 'Staff'
        case '13': return 'Dentist'
        default: return ''
      }
    },
    passwordState: function () {
      if (this.userPassword.length) {
        return this.userPassword.length > 3
      } else {
        return null
      }
    },
    passwordConfirmState: function () {
      if (this.userPassword.length && this.confirmPassword.length) {
        return this.confirmPassword === this.userPassword && this.userPassword.length > 3
      } else {
        return null
      }
    },
    changeButtonState: function () {
      if (this.passwordState && this.passwordConfirmState && !this.waitingResponse) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    signOut: function () {
      if (this.userRole === 'Dentist') {
        this.$store.commit('SET_USER', null)
        this.$router.push({ name: 'DentistLogin' })
      } else {
        this.$store.commit('SET_USER', null)
        this.$router.push({ name: 'StaffLogin' })
      }
    },
    changePassword: function () {
      this.waitingResponse = true
      const dataItem = {
        'form-data': {
          password: this.userPassword
        }
      }
      api
        .request('post', 'GeneralApis/change_password', dataItem)
        .then((response) => {
          console.log(response)
          var data = response.data
          this.waitingResponse = false
          if (data.errorcode) {
            alert(data.errormessage)
          } else {
            alert(data.errormessage)
            this.$bvModal.hide('modal-change-password')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-wrap{
  display: flex;
}
.name-wrap{
  text-align: left;
  .profile-name{
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .profile-role{
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0;
    color: #ACB1B5;
  }
}
.down-icon{
  padding-left: 15px;
  margin: auto;
}
</style>
<style lang="scss">
.profile-drop-down .dropdown-toggle::after{
  display: none;
}
.profile-drop-down .dropdown-toggle{
  background: #F2F5F9;
  border: none;
  border-radius: 15px;
  padding: 15px;
}
.profile-drop-down .dropdown-toggle:hover{
  background: $bg-primary;
}
</style>

<template>
  <div>
    <Header/>
    <b-container class="title-section" fluid>
      <div class="title-wrap">
        <h1>Find a Deutsche certified doctor</h1>
        <b-input-group id="search-group">
          <template #append>
            <b-input-group-text class="bg-primary text-white border-0"><b-icon-search></b-icon-search></b-input-group-text>
          </template>
          <b-form-input v-model="filterText" placeholder="Search for trained dentist"></b-form-input>
        </b-input-group>
      </div>
    </b-container>
    <b-container class="page-content">
      <b-row class="section1">
        <b-col cols="12" md="9" class="text-center">
          <b-card no-body img-left class="p-2" v-for="item in caseList" :key="item.id" :item="item">
            <b-skeleton-img card-img="left" width="150px"></b-skeleton-img>
            <b-card-body>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" md="3">
          <b-card header="Filter case studies">
            <b-form-checkbox
              id="checkbox-1"
              v-model="filter"
              name="checkbox-1"
              value="clear_aligners"
              unchecked-value="not_accepted"
            >
              Clear Aligners
            </b-form-checkbox>
          </b-card>
        </b-col>
      </b-row>
      <start-treatment class="section2"/>
    </b-container>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import StartTreatment from '../components/StartTreatment.vue'
import { BIconSearch } from 'bootstrap-vue'

export default {
  name: 'FindADentist',
  data () {
    return {
      filter: 'clear_aligners',
      caseList: [
        {
          id: 1,
          patient: 'Meet Michael',
          description: "Michael hated his smile, and thought the anterior Crossbite were beyond repair. We aligned Michael's upper and lower teeth so he has the best smile ever.",
          imageBefore: require('../assets/images/result/case1/before.jpg'),
          imageAfter: require('../assets/images/result/case1/after.jpg')
        },
        {
          id: 2,
          patient: 'Meet Jessica',
          description: 'She had sever Crowding in the upper arch which made her uncomfortable when she want to smile front her friends. So she decide to try our treatment she was not expecting this results at all.',
          imageBefore: require('../assets/images/result/case2/before.jpg'),
          imageAfter: require('../assets/images/result/case2/after.jpg')
        },
        {
          id: 3,
          patient: 'Meet Michael',
          description: "Michael hated his smile, and thought the anterior Crossbite were beyond repair. We aligned Michael's upper and lower teeth so he has the best smile ever.",
          imageBefore: require('../assets/images/result/case3/before.jpg'),
          imageAfter: require('../assets/images/result/case3/after.jpg')
        }
      ]
    }
  },
  components: {
    Header,
    Footer,
    BIconSearch,
    StartTreatment
  }
}
</script>

<style lang="scss" scoped>
.title-section{
  background-color: $bg-primary;
  color: $text-secondary;
  text-align: center;
  padding: 70px 15px;
  .title-wrap{
    max-width: 600px;
    margin: 0 auto;
  }
  h1{
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 25px;
  }
  #search-group{
    max-width: 560px;
    margin: 0 auto;
  }
}
.section1{
  margin-top: 55px;
  .card{
    margin-bottom: 15px;
    background: #EEF1FE;
    min-height: 160px;
  }
}
.section2{
  margin-bottom: 100px;
}
</style>

<style lang="scss">
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $bg-primary;
    background-color: $bg-primary;
}
</style>

export default {
  INITIALISE_STORE (state) {
    const userData = localStorage.getItem('userData')
    if (userData) {
      state.user = JSON.parse(userData)
    }
  },
  SET_USER (state, user) {
    state.user = user
    localStorage.setItem('userData', JSON.stringify(user))
  },
  SET_TOKEN (state, token) {
    state.token = token
  },
  CLEAR_TEETH_SELECTION (state) {
    state.selectedTeeths = []
  },
  ADD_TEETH (state, item) {
    state.selectedTeeths.push(item)
  },
  REMOVE_TEETH (state, item) {
    state.selectedTeeths.splice(state.selectedTeeths.findIndex(v => v === item), 1)
  },
  SET_ACTIVE_CASE (state, item) {
    state.activeCase = item
  },
  SET_PRODUCT_LIST (state, item) {
    state.productList = item
  }
}

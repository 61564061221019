<template>
  <b-col id="file-upload-wrap">
    <VueFileAgent
      class="profile-pic-upload-block p-0"
      ref="fileRef"
      :multiple="false"
      :deletable="true"
      :editable="true"
      :meta="false"
      :compact="true"
      :accept="fileExt"
      :helpText="helpText"
      :errorText="{
        type: errorText,
      }"
      v-model="uploadFile"
      @select="onSelect($event)"
      @upload="onUpload($event)"
    >
      <template  v-if="previewStl && uploaded" v-slot:file-preview="previewStl">
        <iframe :id="fileName" :src="'https://www.viewstl.com/?embedded&url=' + filePath" style="border:0;margin:0;width:100%;height:100%;"></iframe>
      </template >
      <template v-slot:before-outer>
      </template >
      <template v-slot:after-inner>
        <span title="after-inner" v-if="!uploaded" class="btn btn-link btn-sm btn-block">{{selectLabel}}</span>
      </template >
      <template v-slot:after-outer>
        <div>
          <p v-if="!uploaded">{{label}}</p>
          <p v-if="uploaded">Click 'Remove' button to remove the file and select new file to upload again.</p>
          <div class="drop-help-text">
            <p class="text-success">Drop the file!</p>
          </div>
          <button type="button" v-if="previewStl" class="btn btn-secondary mr-2" :class="{'disabled': !uploadFile}" @click="reloadModel()">Preview</button>
          <a class="btn btn-secondary mr-2" v-if="downloadFile && filePath !== ''" :href="filePath" target="_blank">Download</a>
          <button type="button" :disabled="uploaded" class="btn btn-info" :class="{'disabled': uploaded || !uploadFile}" @click="upload()">Upload</button>
          <button type="button" class="btn ml-2" :class="[uploaded ? 'btn-danger' : 'btn-secondary']" v-if="uploadFile" @click="remove()">Remove</button>
          <div class="clearfix"></div>
        </div>
      </template >
    </VueFileAgent>
  </b-col>
</template>

<script>
import config from '../config'
export default {
  name: 'FileUpload',
  props: {
    fileData: {
      type: Object,
      default: null
    },
    previewStl: {
      type: Boolean,
      default: false
    },
    previewFile: {
      type: Boolean,
      default: false
    },
    downloadFile: {
      type: Boolean,
      default: false
    },
    fileName: String,
    filePath: String,
    label: String,
    helpText: String,
    errorText: String,
    fileExt: String,
    selectLabel: {
      type: String,
      default: 'Select stl file'
    }
  },
  data () {
    return {
      uploadFile: this.fileData,
      uploaded: this.fileData,
      uploadUrl: config.serverURI + 'GeneralApis/upload',
      deleteUrl: config.serverURI + 'GeneralApis/removefileagent',
      uploadHeaders: { },
      previewStatus: this.previewFile
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    cleanFileName: function (fileName) {
      const stripedFileName = fileName.replace(/\s+/g, '-')
      return stripedFileName.replace(/[^a-zA-Z0-9-]/g, '')
    },
    reloadModel: function () {
      // this.$emit('')
      this.previewStatus = true
      document.getElementById(this.fileName).contentWindow.postMessage({ msg_type: 'load', url: this.filePath }, '*')
    },
    remove: function () {
      var uploadFile = this.uploadFile
      var uploadData = { caseid: this.cleanFileName(this.uploadFile.name()) }
      this.$refs.fileRef.deleteUpload(this.deleteUrl, this.uploadHeaders, [uploadFile], uploadData)
      this.uploadFile = null
      this.uploaded = false
      this.$emit('remove-file')
    },
    upload: function () {
      var self = this
      this.$refs.fileRef.upload(this.uploadUrl, this.uploadHeaders, [this.uploadFile], function createFormData (fileData) {
        var formData = new FormData()
        formData.append('id', self.cleanFileName(self.uploadFile.name().split('.').slice(0, -1).join('.')))
        formData.append('file', fileData.file)
        formData.append('filename_ext', self.uploadFile.ext)
        const escapedFilePath = self.$store.state.activeCase.case_name.replace(/\s+/g, '-')
        formData.append('file_path', self.cleanFileName(escapedFilePath.replace('/', '-')))
        return formData
      }).then(function () {
        self.uploaded = true
        // self.$emit('update-filename')
        setTimeout(function () {
          // self.uploadFile.progress(0);
        }, 500)
      })
    },
    onSelect: function (fileRecords) {
      console.log('onSelect', fileRecords)
      this.uploaded = false
      console.log('Uploaded File', fileRecords[0].name)
      console.log('Uploaded File Name', this.cleanFileName(this.uploadFile.name().split('.').slice(0, -1).join('.')))
    },
    onUpload (responses) {
      console.log('file upload result', responses)
      if (responses[0].data) {
        console.log('file name:', responses[0].fileRecord.name())
        const filePath = responses[0].data.filename.split('.').slice(1, 3).join('.')
        // const fileName = this.cleanFileName(this.uploadFile.name().split('.').slice(0, -1).join('.'))
        const fileName = responses[0].data.upload_data.file_name
        const fileParts = filePath.substring(0, filePath.indexOf(this.uploadFile.name()))
        console.log('File Path: ', fileParts)
        console.log('File Name: ', fileName)
        const fullPath = fileParts + fileName
        console.log('Full Path: ', fullPath)
        this.$emit('update-filename', fullPath)
      }
    }
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #file-upload-wrap .drop-help-text {
    display: none;
  }
  #file-upload-wrap .is-drag-over .drop-help-text {
    display: block;
  }
  #file-upload-wrap .profile-pic-upload-block {
    border: 2px dashed transparent;
    padding: 20px;
    padding-top: 0;
  }

  #file-upload-wrap .is-drag-over.profile-pic-upload-block {
    border-color: #AAA;
  }
  #file-upload-wrap .vue-file-agent {
    width: 160px;
    float: left;
    margin: 0 15px 5px 0;
    border: 0;
    box-shadow: none;
  }
  @media screen and (max-width: 425px) {
    #file-upload-wrap .vue-file-agent {
      float: none;
    }
  }
</style>

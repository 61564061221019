import axios from 'axios'
import config from '../config'
import store from '../store'

export default {
  request (method, uri, data = null, isLogin = false) {
    if (!method) {
      console.error('API function call requires method argument')
      return
    }

    if (!uri) {
      console.error('API function call requires uri argument')
      return
    }
    var url = config.serverURI + uri
    console.log(data)
    var inputs = data
    if (!isLogin) {
      inputs.auth = store.state.user
    }
    var params = {
      inputs,
      'modecode': 100
    }
    console.log(params)
    data = JSON.stringify(params)
    return axios({ method, url, data })
  }
}

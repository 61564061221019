<template>
  <div class="login-page">
    <section id="content" class="p-t-lg wrapper-md animated fadeInUp">
      <div class="container aside-xl" style="text-align: center">
        <img src="@/assets/images/logo.png" />
        <h2 style="" align="center">{{getTitle}}</h2>
        <section class="form-wrap">
          <form @submit.prevent="checkCreds">
            <div class="">
              <div class="" style="padding-top: 20px">
                <input
                  type="text"
                  required=""
                  v-model="username"
                  placeholder="User Name"
                  autocomplete="off"
                  class="form-control no-border"
                />
                <span class="help-block"> <strong></strong></span>
              </div>
              <div class="">
                <input
                  type="password"
                  v-model="password"
                  placeholder="Password"
                  required=""
                  name="password_of"
                  autocomplete="off"
                  class="form-control no-border"
                />
                <span class="help-block">
                  <strong></strong>
                </span>
              </div>
            </div>
            <b-button variant="primary" type="sumbit">Login</b-button>
            <p class="mt-2 text-red">{{error}}</p>
          </form>
        </section>
      </div>
    </section>

  </div>
</template>

<script>
import api from '../api'

export default {
  name: 'StaffLogin',
  data () {
    return {
      username: '',
      password: '',
      response: '',
      error: ''
    }
  },
  computed: {
    getTitle: function () {
      if (this.$route.name === 'StaffLogin') {
        return 'Staff Login'
      } else {
        return 'Dentist Login'
      }
    }
  },
  methods: {
    checkCreds () {
      const { username, password } = this
      const inputs = {
        'login-info': {
          'username': username,
          'password': password,
          'platform': 'WEB',
          'app_key': 'ADMIN_WEB_CLIENT',
          'api_key': 'Deutsche_key_2021',
          'device_notify_id': '',
          'device_UID': '12548795522'
        }
      }
      this.error = ''
      api
        .request('post', 'GeneralApis/portal_user_auth', inputs, 'login')
        .then(response => {
          console.log(response)
          var data = response.data
          if (data.errorcode) {
            this.error = data.errormessage
          }
          if (data.authInfo) {
            var userAuth = data.authInfo
            console.log(userAuth)
            userAuth.auth_key = userAuth.device_access_key
            userAuth.app_key = 'CUSTOMER_WEB_CLIENT'
            userAuth.api_key = 'Deutsche_key_2021'
            this.$store.commit('SET_USER', userAuth)

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(userAuth))
            }

            this.$router.push(data.redirect ? data.redirect : { name: 'Dashboard' })
          }
        }) /*
      if(username == 'admin' && password =="000"){
        this.$store.commit('SET_USER', {'username':username,'password':password})
        this.$router.push('/dashboard');
      }
      else
        return; */
    }
  }
}
</script>

<style scoped>
h2{
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 10px;
}
.login-page{
  /*background-image: url('~@/assets/login-bg.jpg');*/
  height: 100vh;
  background-position: center;
  background-size: cover;
}
.bg-dark {
  background-color: #222733;
  color: #7a87a7;
  height: 100%;
}
.contact-link{
  color: #444;
}
#content{
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255,255,255,0.5);
  padding-top: 20px
}
.form-wrap{
  width: 200px;
  margin: 0 auto;
}
input {
  padding: 10px;
  width: 200px;
  margin-bottom: 5px;
  border: 1px solid #3d9dd7;
}
button {
  width: 200px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import WhyDeutsche from '../views/WhyDeutsche.vue'
import HowItWork from '../views/HowItWork.vue'
import CompareUs from '../views/CompareUs.vue'
import ContactUs from '../views/ContactUs.vue'
import Result from '../views/Result.vue'
import FindADentist from '../views/FindADentist.vue'
import StaffLogin from '../views/StaffLogin.vue'
import Dashboard from '../views/Dashboard.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
    ],
    meta: {
      title: 'Deutsche Aligners - The clear German way of teeth straightening',
      metaTags: [
        {
          name: 'description',
          content: 'Creating the healthy smile you want through science and artistry. Dedicated to excellence. Dedicated to your smile.'
        }
      ]
    }
  },
  {
    path: '/why-deutsche',
    name: 'WhyDeutsche',
    component: WhyDeutsche,
    meta: {
      title: 'Why Deutsche Align',
      metaTags: [
        {
          name: 'description',
          content: 'At Deutsche, you can be sure of receiving expert advice on the available options before treatment begins, and confident of being in safe hands throughout.'
        }
      ]
    }
  },
  {
    path: '/how-it-work',
    name: 'HowItWork',
    component: HowItWork,
    meta: {
      title: 'How does Deutsche® treatment work?',
      metaTags: [
        {
          name: 'description',
          content: 'Lunch with friends? Popcorn at the movies? The sports you love? With removable clear aligners, you can continue to enjoy your favorite meals AND keep playing the sports you love – no timeouts needed.'
        }
      ]
    }
  },
  {
    path: '/compare-us',
    name: 'CompareUs',
    component: CompareUs,
    meta: {
      title: 'Compare us',
      metaTags: [
        {
          name: 'description',
          content: 'Still wondering which treatment is right for you? Explore our easy comparison chart below to find out about the advantages of each treatment.'
        }
      ]
    }
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      title: 'Contact us',
      metaTags: [
        {
          name: 'description',
          content: 'At  Deutsche, you can be sure of receiving expert advice on the available options before treatment begins, and confident of being in safe hands throughout.'
        }
      ]
    }
  },
  {
    path: '/result',
    name: 'Result',
    component: Result,
    meta: {
      title: ' Real Patients. Real Results',
      metaTags: [
        {
          name: 'description',
          content: 'See the smile transformations created by Deutsche trained dentist'
        }
      ]
    }
  },
  {
    path: '/find-a-dentist',
    name: 'FindDentist',
    component: FindADentist,
    meta: {
      title: 'Find a Deutsche certified doctor',
      metaTags: [
        {
          name: 'description',
          content: 'Search for trained dentist'
        }
      ]
    }
  },
  {
    path: '/dentist-login',
    name: 'DentistLogin',
    component: StaffLogin
  },
  {
    path: '/staff-login',
    name: 'StaffLogin',
    component: StaffLogin
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))
  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()
  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')
    return tag
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router

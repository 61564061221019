<template>
    <ul class="list-unstyled">
        <li v-for="(comment, key) in items" :key="key" class="media mb-4">
            <b-avatar class="mr-3 mt-2" variant="light"></b-avatar>
            <comment :comment="comment"/>
        </li>
    </ul>
</template>

<script>
import Comment from './Comment.vue'
export default {
  name: 'Comments',
  props: {
    items: Array
  },
  components: {
    Comment
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

h3 {
    margin-top: 2%;
    margin-left: 27%;
    font-family: 'Manrope', sans-serif;
    font-weight: bold
}

.card {
    border: none;
    border-radius: 20px;
    padding-right: 20px;
    margin-bottom: 40px
}

.round .align-self-start {
    border-radius: 100%;
    width: 45px;
    height: 40px
}

.media .comment {
    background: #F4F4F4;
    border: none;
    border-radius: 10px
}

h6.user {
    color: #5C5C5C;
    font-size: 15px !important;
    padding-left: 15px !important;
    margin-bottom: 0
}

h6.user:hover {
    cursor: pointer;
    text-decoration: underline
}

p.text {
    margin-bottom: 0;
    color: #8A8A8A !important;
    font-size: 14px
}

.ml-auto {
    margin-right: 10px
}

p .reply {
    color: #5C5C5C;
    font-size: 15px
}
@media screen and (min-width: 992px) {
    .media-body{
        min-width: 320px;
    }
}
</style>

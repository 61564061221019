<template>
  <b-container class="title-section" fluid>
    <div class="title-wrap">
      <h1>{{title}}</h1>
      <p>{{desc}}</p>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'TitleSection',
  props: ['title', 'desc'],
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.title-section{
  background-color: $bg-primary;
  color: $text-secondary;
  text-align: center;
  padding: 80px 15px;
  .title-wrap{
    max-width: 600px;
    margin: 0 auto;
  }
  h1{
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 25px;
  }
  p{
    font-size: 16px;
    line-height: 20px;
  }
}
</style>

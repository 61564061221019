<template>
  <div>
    <Header/>
    <title-section
      title="Real Patients. Real Results"
      desc="See the smile transformations created by Deutsche trained dentist"
    />
    <b-container class="page-content">
      <b-row class="section1">
        <b-col cols="12" md="9" class="text-center">
          <case-study v-for="item in caseList" :key="item.id" :item="item"/>
          <b-button variant="primary">See more</b-button>
        </b-col>
        <b-col cols="12" md="3">
          <b-card header="Filter case studies">
            <b-form-checkbox
              id="checkbox-1"
              v-model="filter"
              name="checkbox-1"
              value="clear_aligners"
              unchecked-value="not_accepted"
            >
              Clear Aligners
            </b-form-checkbox>
          </b-card>
        </b-col>
      </b-row>
      <start-treatment class="section2"/>
    </b-container>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TitleSection from '../components/TitleSection.vue'
import StartTreatment from '../components/StartTreatment.vue'
import CaseStudy from '../components/CaseStudy.vue'

export default {
  name: 'Result',
  data () {
    return {
      filter: 'clear_aligners',
      caseList: [
        {
          id: 1,
          patient: 'Meet Michael',
          description: "Michael hated his smile, and thought the anterior Crossbite were beyond repair. We aligned Michael's upper and lower teeth so he has the best smile ever.",
          imageBefore: require('../assets/images/result/case1/before.jpg'),
          imageAfter: require('../assets/images/result/case1/after.jpg')
        },
        {
          id: 2,
          patient: 'Meet Jessica',
          description: 'She had sever Crowding in the upper arch which made her uncomfortable when she want to smile front her friends. So she decide to try our treatment she was not expecting this results at all.',
          imageBefore: require('../assets/images/result/case2/before.jpg'),
          imageAfter: require('../assets/images/result/case2/after.jpg')
        },
        {
          id: 3,
          patient: 'Meet Michael',
          description: "Michael hated his smile, and thought the anterior Crossbite were beyond repair. We aligned Michael's upper and lower teeth so he has the best smile ever.",
          imageBefore: require('../assets/images/result/case3/before.jpg'),
          imageAfter: require('../assets/images/result/case3/after.jpg')
        }
      ]
    }
  },
  components: {
    Header,
    Footer,
    TitleSection,
    StartTreatment,
    CaseStudy
  }
}
</script>

<style lang="scss" scoped>
.section1{
  margin-top: 55px;
}
.section2{
  margin-bottom: 100px;
}
</style>

<style lang="scss">
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $bg-primary;
    background-color: $bg-primary;
}
</style>

<template>
  <b-card
    border-variant="secondary"
    :header='item.name'
    header-border-variant="secondary"
    align="center"
    class="case-item"
  >
    <!-- v-on:click="$emit('show-details', item)" -->
    <!-- <b-card-text>{{getPatient}}</b-card-text> -->
    <template #header>
      <h6 class="title" v-on:click="getOrderDetails">{{item.case_name}}</h6>
    </template>
    <b-badge class="revision" v-if="isRevision">Revision</b-badge>
    <b-list-group flush @click="getOrderDetails" class="list">
      <b-list-group-item>Patient:<br/>{{getPatient}}</b-list-group-item>
      <b-list-group-item>Dentist:<br/>{{getDentist}}</b-list-group-item>
    </b-list-group>
    <b-button size="sm" variant="outline-secondary" @click="markAsRead()" class="mark-read" v-if="isMessage">Mark as Read</b-button>
  </b-card>
</template>

<script>
// import api from '../api'
export default {
  name: 'Case',
  props: {
    item: Object,
    isRevision: {
      type: Boolean,
      default: false
    },
    isMessage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  components: {
  },
  computed: {
    getPatient: function () {
      return this.item.first_name + ' ' + this.item.last_name
    },
    getDentist: function () {
      return this.item.dentist_firstname + ' ' + this.item.dentist_lastname
    }
  },
  methods: {
    getOrderDetails: function () {
      this.$emit('show-details', this.item)
    },
    markAsRead: function () {
      this.$emit('show-message', this.item.id)
    }
  },
  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.case-item{
  .card-header{
    background: #ACB1B5;
    color: $bg-primary;
  }
  .card-body, .list-group-item{
    background: #F2F5F9;
  }
  .list-group-item{
    font-size: 16px;
    line-height: 22px;
  }
}
h1 {
  font-size: 24px;
}
.card {
  //cursor: pointer;
}
.title, .list{
  cursor: pointer;
}
.title{
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
}
.revision{
  position: absolute;
  top: 55px;
  margin-left: -30px;
  z-index: 10;
}
.mark-read{
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 10;
}
</style>

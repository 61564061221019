<template>
    <div class="media-body">
        <div class="row d-flex">
            <h6 class="user pt-2">{{getName}}</h6>
            <div class="ml-auto">
                <p class="text">{{getDateTime(comment.date)}}</p>
            </div>
        </div>
        <b-row>
            <b-col cols="10">
                <p class="text">{{comment.comment}}</p>
            </b-col>
            <b-col>
                <b-button v-if="comment.file && comment.file !== ''" class="mt-2" variant="outline-info" :href="getFilePath(comment.file)" target="_blank" download v-b-tooltip.hover title="Download Attachment" size="sm"><b-icon-download font-scale="1"/></b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BIconDownload } from 'bootstrap-vue'
export default {
  name: 'Comment',
  props: {
    comment: Object
  },
  components: {
    BIconDownload
  },
  computed: {
    getName: function () {
      return this.comment.first_name + ' ' + this.comment.last_name
    }
  },
  methods: {
    getDateTime: function (datetime) {
      // Split timestamp into [ Y, M, D, h, m, s ]
      var t = datetime.split(/[- :]/)
      // Apply each element to the Date function
      var date = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
      var hours = date.getHours()
      var minutes = date.getMinutes()
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours !== 0 ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes
      var strTime = hours + ':' + minutes + ' ' + ampm
      const month = date.getMonth() + 1
      return date.getDate() + '/' + month + '/' + date.getFullYear() + ' ' + strTime
    },
    getFilePath: function (path) {
      return path.replace('uploads//uploads', 'uploads')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

h3 {
    margin-top: 2%;
    margin-left: 27%;
    font-family: 'Manrope', sans-serif;
    font-weight: bold
}

.card {
    border: none;
    border-radius: 20px;
    padding-right: 20px;
    margin-bottom: 40px
}

.round .align-self-start {
    border-radius: 100%;
    width: 45px;
    height: 40px
}

.media .comment {
    background: #F4F4F4;
    border: none;
    border-radius: 10px
}

h6.user {
    color: #5C5C5C;
    font-size: 15px !important;
    padding-left: 15px !important;
    margin-bottom: 0
}

h6.user:hover {
    cursor: pointer;
    text-decoration: underline
}

p.text {
    margin-bottom: 0;
    color: #8A8A8A !important;
    font-size: 14px
}

.ml-auto {
    margin-right: 10px
}

p .reply {
    color: #5C5C5C;
    font-size: 15px
}
@media screen and (min-width: 992px) {
    .media-body{
        min-width: 320px;
    }
}
</style>

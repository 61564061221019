<template>
  <b-container fluid>
     <div class="content">
       <div class="top-section">
        <div class="left-col">
          <img src="@/assets/images/logo.png" alt="Deutsche">
        </div>
        <div class="right-col">
          <div class="title-section">
            <div class="title-wrap">
              <h1>My Cases</h1>
              <p>Get summary of your weekly update</p>
            </div>
            <div class="profile-menu">
              <profile-drop-down/>
            </div>
          </div>
          <b-row>
            <b-col>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="bg-white border-right-0"><b-icon-search></b-icon-search></b-input-group-text>
                </template>
                <b-form-input v-model="filterText" class="border-left-0"></b-form-input>

                <template #append>
                  <b-dropdown :text="selectedFilter" variant="primary">
                    <b-dropdown-item @click="selectedFilter = 'Case#'">Case#</b-dropdown-item>
                    <b-dropdown-item @click="selectedFilter = 'Patient'">Patient</b-dropdown-item>
                    <b-dropdown-item @click="selectedFilter = 'Dentist'">Dentist</b-dropdown-item>
                    <b-dropdown-item @click="selectedFilter = 'Clinic'">Clinic</b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-input-group>
            </b-col>
            <b-col class="text-right">
              <b-button v-if="!showCaseDetails && !uploadUser" variant="primary" @click="newCase()" class="mb-2 mr-2">New Case</b-button>
              <b-button v-if="!showCaseDetails && !uploadUser" variant="primary" @click="refineCase()" class="mb-2 mr-2">Refinement</b-button>
              <b-button v-if="!showCaseDetails" variant="primary" @click="refreshCases()" class="mb-2">Refresh Cases</b-button>
            </b-col>
          </b-row>
        </div>
       </div>
      <CaseList @refresh-cases="getCaseList()" :saleOrders="caseList"/>
     </div>
     <b-modal
        id="modal-new-case"
        size="xl"
        footer-class="border-0 p-0"
        header-class="border-0"
      >
        <template #modal-header="{}">
        </template>
        <new-case @refresh-cases="getCaseList()"/>
        <template #modal-footer>
          <div class="p-0"></div>
        </template>
     </b-modal>
  </b-container>
</template>

<script>
import ProfileDropDown from '../components/ProfileDropDown.vue'
import CaseList from '../components/CaseList.vue'
import { BIconSearch } from 'bootstrap-vue'
import NewCase from '../components/NewCase.vue'
import api from '../api'
export default {
  components: { ProfileDropDown, CaseList, BIconSearch, NewCase },
  name: 'Dashboard',
  props: [],
  data () {
    return {
      filter: {
        caseNo: '',
        patient: '',
        dentist: '',
        clinic: ''
      },
      filterText: '',
      selectedFilter: 'Case#',
      caseList: []
    }
  },
  computed: {
    showCaseDetails: function () {
      return this.$store.state.showCaseDetails
    },
    uploadUser: function () {
      return this.$store.state.user.user_role === '12'
    }
  },
  methods: {
    getCaseList: function () {
      this.waitingResponse = true
      const inputs = {
        filter: {
        }
      }
      console.log('Case listing input', inputs)
      api
        .request('post', 'GeneralApis/get_case_list', inputs)
        .then((response) => {
          this.waitingResponse = false
          console.log('Case listing API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.caseList) {
            console.log('case list:', data.caseList)
            this.caseList = data.caseList
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    getProductList: function () {
      this.waitingResponse = true
      const inputs = {
        filter: {
        }
      }
      console.log('Product List input', inputs)
      api
        .request('post', 'GeneralApis/get_product', inputs)
        .then((response) => {
          this.waitingResponse = false
          console.log('Product List API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.productList) {
            console.log('Product list:', data.productList)
            this.$store.commit('SET_PRODUCT_LIST', data.productList)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    newCase: function () {
      this.$bvModal.show('modal-new-case')
    }
  },
  mounted () {
    this.getCaseList()
    if (!this.$store.state.productList.length) {
      this.getProductList()
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  margin-left: 45px;
  margin-right: 45px;
  .left-col, .right-col{
    padding-top: 30px;
  }
  .title-section{
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .top-section{
    display: flex;
    .left-col{
      flex: 0 0 235px;
      border-right: 1px solid #EBEBEB;
    }
    .right-col{
      padding-left: 30px;
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="case-study">
    <h2>{{item.patient}}</h2>
    <p>{{item.description}}</p>
    <img :src="item.imageBefore" class="mr-3"/>
    <img :src="item.imageAfter"/>
  </div>
</template>

<script>
export default {
  name: 'CaseStudy',
  props: ['item'],
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.case-study{
  background: #F2F5F9;
  border: 1px solid #D6D8DA;
  box-shadow: 0px 4px 4px 0px #D1D1D140;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 20px;
  text-align: left;
}
</style>

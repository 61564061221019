<template>
  <b-container id="case-details" class="text-left">
    <h1 class="text-center">Case Details</h1>
    <b-row>
      <b-col class="mb-4">
        <b-list-group>
          <b-list-group-item variant="secondary" class="d-flex justify-content-between align-items-center">
            <span><b-icon-hash class="border border-secondary rounded mr-2" variant="secondary"></b-icon-hash>Case#: {{currentItem.case_name}}</span>
            <b-button variant="outline-secondary" v-if="parentCase" @click="backToParent()">Back</b-button>
          </b-list-group-item>
          <b-list-group-item variant="light"><b-icon-calendar-2-date class="border border-secondary rounded mr-2" variant="secondary"></b-icon-calendar-2-date>Order Date: {{getDateTime(currentItem.added_date)}}</b-list-group-item>
          <b-list-group-item variant="secondary"><b-icon-person-fill class="border border-secondary rounded mr-2" variant="secondary"></b-icon-person-fill>Dentist: {{getDentist}}</b-list-group-item>
          <b-list-group-item variant="light"><b-icon-plus-square-fill class="border border-secondary rounded mr-2" variant="secondary"></b-icon-plus-square-fill>Clinic: {{currentItem.clinic_name}}</b-list-group-item>
          <b-list-group-item variant="secondary"><b-icon icon="person-fill" class="border border-secondary rounded mr-2" variant="secondary"></b-icon>Patient: {{getPatient}}</b-list-group-item>
          <b-list-group-item variant="light"><b-icon-calendar class="border border-secondary rounded mr-2" variant="secondary"></b-icon-calendar>Age: {{currentItem.age}}</b-list-group-item>
          <b-list-group-item variant="secondary"><b-icon-bullseye class="border border-secondary rounded mr-2" variant="secondary"></b-icon-bullseye>Impression Type: {{currentItem.impression_type}}</b-list-group-item>
          <b-list-group-item variant="light"><b-icon-view-list class="border border-secondary rounded mr-2" variant="secondary"></b-icon-view-list>Teeth #: {{currentItem.selected_teeths}}</b-list-group-item>
          <b-list-group-item variant="secondary" v-if="currentItem.dispatch_date"><b-icon-calendar class="border border-secondary rounded mr-2" variant="secondary"></b-icon-calendar>Dispatch Date: {{currentItem.dispatch_date}}</b-list-group-item>
          <b-list-group-item variant="light" v-if="currentItem.no_of_aligners"><b-icon-view-list class="border border-secondary rounded mr-2" variant="secondary"></b-icon-view-list>No. of Aligners Sent: {{currentItem.no_of_aligners}}</b-list-group-item>
          <b-list-group-item variant="secondary" v-if="currentItem.arch_sent"><b-icon-bullseye class="border border-secondary rounded mr-2" variant="secondary"></b-icon-bullseye>Arch Sent: {{currentItem.arch_sent}}</b-list-group-item>
          <b-list-group-item variant="light" v-if="currentItem.free_refinement_date"><b-icon-calendar class="border border-secondary rounded mr-2" variant="secondary"></b-icon-calendar>Free Refinement Until: {{currentItem.free_refinement_date}}</b-list-group-item>
          <b-list-group-item v-if="getAttachments.length" variant="secondary"><b-icon-files class="mr-2" variant="secondary"></b-icon-files>Attachements:
            <b-row>
            <b-col v-for="(item, index) in getAttachments" :key="index">
            <b-input-group class="mb-2" size="sm">
              <b-input-group-text class="border-right-0 border-secondary p-1" size="sm">
                {{item.label}}
              </b-input-group-text>
              <b-input-group-append size="sm">
                <b-button variant="outline-secondary" size="sm" :href="item.url" :download="item.label"><b-icon-download/></b-button>
                <b-button variant="outline-secondary" size="sm" v-if="item.isImage" :href="item.url" target="_blank"><b-icon-eye/></b-button>
              </b-input-group-append>
            </b-input-group>
            </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
        <b-list-group class="mt-3">
          <b-list-group-item v-if="Array.isArray(currentItem.related_case_id)" variant="info">Related Cases</b-list-group-item>
          <b-list-group-item v-for="(relatedCase, key) in currentItem.related_case_id" :key="key" class="d-flex justify-content-between align-items-center">
          {{relatedCase[1]}}
          <b-button variant="outline-info" @click="getRelatedCase(relatedCase)">View</b-button>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col v-if="uploadAllowed && parentCase === null && tabIndex < 4">
        <file-upload
          :fileData="currentItem.video_url && currentItem.video_url !== '' ? {'name':currentItem.case_id + '.mp4', 'ext': 'mp4', 'type': 'video/mp4', 'url':currentItem.video_url } : null"
          v-on:update-filename="videoUrl = uploadUrl + $event"
          v-on:remove-file="videoUrl = ''"
          :fileName="currentItem.case_id"
          :filePath="videoUrl"
          :downloadFile = true
          :fileExt="'video/mp4'"
          :helpText="'Drag a video file here'"
          :errorText="'Please select a video'"
          :label="'Please select a video and click the upload button'"
          :selectLabel="'Select video file'"
        />
        <file-upload
            :fileData="currentItem.pdf_url !== '' ? {'name':currentItem.pdf_url, 'ext': 'pdf', 'type': 'application/pdf'} : null"
            v-on:update-filename="pdfUrl = uploadUrl + $event"
            v-on:remove-file="pdfUrl = ''"
            :fileName="currentItem.case_id"
            :filePath="pdfUrl"
            :fileExt="'.pdf'"
            :downloadFile = true
            :helpText="'Drag a pdf file here'"
            :errorText="'Please select a pdf'"
            :label="'Please select a pdf and click the upload button'"
            :selectLabel="'Select pdf file'"
          />
      </b-col>
      <b-col cols="12" md="6" v-if="(!uploadAllowed && !newCase) || (parentCase && !newCase) || tabIndex > 3">
        <a class="btn btn-warning btn-lg btn-block mb-2" :href="currentItem.pdf_url" target="_blank" download>Download IPR Guide</a>
        <video width="100%" controls>
          <source :src="currentItem.video_url" type="video/mp4">
        Your browser does not support the video tag.
        </video>
        <a class="btn btn-info btn-lg btn-block mb-2" :href="currentItem.video_url" target="_blank" download>Download Video</a>
      </b-col>
    </b-row>
    <b-row v-if="uploadAllowed || (!uploadAllowed && !newCase)">
      <b-col>
        <b-card
          header="STL Files"
          header-tag="header"
          class="mb-5 mt-3"
          v-if="uploadAllowed && parentCase === null && tabIndex < 4"
        >
          <b-row class="mb-3">
            <b-col col md="6" class="mb-3">
              <h6 class="pl-4">Upper Arch Before</h6>
              <file-upload
                :fileData="currentItem.stl_uab !== '' ? {'name':currentItem.stl_uab, 'ext': 'stl', 'type': 'application/stl'} : null"
                v-on:update-filename="stlUab = uploadUrl + $event"
                v-on:remove-file="stlUab = ''"
                :fileName="currentItem.id + '-uab'"
                :filePath="stlUab"
                :fileExt="'.stl'"
                :helpText="'Drag a Stl file here'"
                :errorText="'Please select a Stl file'"
                :label="'Please select a stl file and click the upload button'"
                :previewStl= true
                :previewFile = true
              />
            </b-col>
            <b-col col md="6" class="mb-3">
              <h6 class="pl-4">Upper Arch After</h6>
              <file-upload
                :fileData="currentItem.stl_uaa !== '' ? {'name':currentItem.stl_uaa, 'ext': 'stl', 'type': 'application/stl'} : null"
                v-on:update-filename="stlUaa = uploadUrl + $event"
                v-on:remove-file="stlUaa = ''"
                :fileName="currentItem.id + '-uaa'"
                :filePath="stlUaa"
                :fileExt="'.stl'"
                :helpText="'Drag a Stl file here'"
                :errorText="'Please select a Stl file'"
                :label="'Please select a stl file and click the upload button'"
                :previewStl= true
              />
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col col md="6" class="mb-3">
              <h6 class="pl-4">Lower Arch Before</h6>
              <file-upload
                :fileData="currentItem.stl_lab !== '' ? {'name':currentItem.stl_lab, 'ext': 'stl', 'type': 'application/stl'} : null"
                v-on:update-filename="stlLab = uploadUrl + $event"
                v-on:remove-file="stlLab = ''"
                :fileName="currentItem.id + '-lab'"
                :filePath="stlLab"
                :fileExt="'.stl'"
                :helpText="'Drag a Stl file here'"
                :errorText="'Please select a Stl file'"
                :label="'Please select a stl file and click the upload button'"
                :previewStl= true
              />
            </b-col>
            <b-col col md="6" class="mb-3">
              <h6 class="pl-4">Lower Arch After</h6>
              <file-upload
                :fileData="currentItem.stl_laa !== '' ? {'name':currentItem.stl_laa, 'ext': 'stl', 'type': 'application/stl'} : null"
                v-on:update-filename="stlLaa = uploadUrl + $event"
                v-on:remove-file="stlLaa = ''"
                :fileName="currentItem.id + '-laa'"
                :filePath="stlLaa"
                :fileExt="'.stl'"
                :helpText="'Drag a Stl file here'"
                :errorText="'Please select a Stl file'"
                :label="'Please select a stl file and click the upload button'"
                :previewStl= true
              />
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col col md="6" class="mb-3">
              <h6 class="pl-4">Occlusion Before</h6>
              <file-upload
                :fileData="currentItem.stl_ob !== '' ? {'name':currentItem.stl_ob, 'ext': 'stl', 'type': 'application/stl'} : null"
                v-on:update-filename="stlOb = uploadUrl + $event"
                v-on:remove-file="stlOb = ''"
                :fileName="currentItem.id + '-ob'"
                :filePath="stlOb"
                :fileExt="'.stl'"
                :helpText="'Drag a Stl file here'"
                :errorText="'Please select a Stl file'"
                :label="'Please select a stl file and click the upload button'"
                :previewStl= true
              />
            </b-col>
            <b-col col md="6" class="mb-3">
              <h6 class="pl-4">Occlusion After</h6>
              <file-upload
                :fileData="currentItem.stl_oa !== '' ? {'name':currentItem.stl_oa, 'ext': 'stl', 'type': 'application/stl'} : null"
                v-on:update-filename="stlOa = uploadUrl + $event"
                v-on:remove-file="stlOa = ''"
                :fileName="currentItem.id + '-oa'"
                :filePath="stlOa"
                :fileExt="'.stl'"
                :helpText="'Drag a Stl file here'"
                :errorText="'Please select a Stl file'"
                :label="'Please select a stl file and click the upload button'"
                :previewStl= true
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card
          header="STL Files"
          header-tag="header"
          class="mb-5"
          v-if="!uploadAllowed || parentCase || tabIndex > 3"
        >
          <b-card-group deck class="mb-3">
            <b-card class="text-center mx-2">
              <b-form-group
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  id="btn-radios-before"
                  v-model="activeStl"
                  :options="stlFiles"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-info"
                  name="radio-btn-outline"
                  buttons
                  @change="loadStl"
                ></b-form-radio-group>
              </b-form-group>
              <div style="height:300px">
                <div v-if="activeStl ===''" class="d-flex align-items-center justify-content-center h-100" ><p>No STL File</p></div>
                <iframe v-if="activeStl !==''" id="vs_iframe" :src="'https://www.viewstl.com/?embedded&url=' + activeStl" style="border:0;margin:0;width:100%;height:100%;"></iframe>
                </div>
              <b-button v-if="activeStl !==''" class="mt-2" variant="outline-info" :href="activeStl" target="_blank" download v-b-tooltip.hover title="Download"><b-icon-download class="mr-2"/>Download</b-button>
            </b-card>
            <b-card class="text-center mx-2">
              <b-form-group
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  id="btn-radios-after"
                  v-model="activeStl2"
                  :options="stlFiles2"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-info"
                  name="radio-btn-outline"
                  buttons
                  @change="loadStl2"
                ></b-form-radio-group>
              </b-form-group>
              <div style="height: 300px">
                <div v-if="activeStl2 ===''" class="d-flex align-items-center justify-content-center h-100" ><p>No STL File</p></div>
                <iframe v-if="activeStl2 !==''" id="vs_iframe" :src="'https://www.viewstl.com/?embedded&url=' + activeStl2" style="border:0;margin:0;width:100%;height:100%;"></iframe>
                </div>
              <b-button v-if="activeStl2 !==''" class="mt-2" variant="outline-info" :href="activeStl2" target="_blank" download v-b-tooltip.hover title="Download"><b-icon-download class="mr-2"/>Download</b-button>
            </b-card>
          </b-card-group>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" xs="12" v-if="uploadAllowed && parentCase === null && tabIndex < 3">
        <label>Lab Charge Items:</label>
        <b-table-simple hover small caption-top responsive v-if="labItems.length">
          <b-thead head-variant="info">
            <b-tr>
              <b-th colspan="3">Item</b-th>
              <b-th colspan="2">Price</b-th>
              <b-th colspan="1" class="qty-cell">Disc %</b-th>
              <b-th colspan="1" class="qty-cell">Discount</b-th>
              <b-th colspan="2" class="total-cell">Net Price</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
          <lab-item
            v-for="(product, k) in labItems"
            :key="k"
            :index="k"
            :productList="labChargeItems"
            :product="product"
            :userId="item.created_by"
            @update-total="updateLabCharge"
            @remove-row="removeLabItem(product)"
          />
            <b-tr>
              <b-th colspan="7" class="text-right">Total Amount</b-th>
              <b-td>{{getCurrency + ' ' + totalLabCharge}}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th colspan="7" class="text-right">Discount</b-th>
              <b-td>{{getCurrency + ' ' + totalLabDiscount}}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th colspan="7" class="text-right">Net Amount</b-th>
              <b-td>{{getCurrency + ' ' + (totalLabCharge - totalLabDiscount)}}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <!--
        <b-input-group class="mt-3" v-for="(item, index) in labItems" :key="index">
          <b-input-group-prepend class="mr-2 align-items-center remove-item">
            <label class="mb-0">{{item.currency + ' ' + item.price}}</label>
          </b-input-group-prepend>
          <b-form-select v-model="item.product" @change="getLabCharges(index)">
            <b-form-select-option v-for="prod in labChargeItems"
              :key="prod.product_id" :value="prod.product_id">{{prod.product_name}}</b-form-select-option>
          </b-form-select>
          <b-input-group-append class="ml-2 align-items-center remove-item">
            <b-icon-trash variant="danger" @click="removeLabItem(item)"/>
          </b-input-group-append>
        </b-input-group>
        <div class="mb-3">
        <b-link @click="addLabItem()">Add more</b-link>
        </div>
        <b-form-group id="input-group-lab-charges" label="Lab Charges:" label-for="input-lab-charges">
          <b-input-group size="sm" :prepend="getCurrency">
            <b-form-input class="ml-2" v-model="totalLabCharge" plaintext></b-form-input>
          </b-input-group>
        </b-form-group>
        -->
      </b-col>
      <b-col v-if="(!uploadAllowed && parentCase === null && !newCase) || (uploadAllowed && tabIndex > 2)">
        <b-table-simple hover small caption-top responsive v-if="caseProducts.length">
         <caption>Lab Charge Items:</caption>
          <b-thead head-variant="info">
            <b-tr>
              <b-th>Item</b-th>
              <b-th>Price</b-th>
              <b-th class="qty-cell" v-if="calcTotalDiscount">Disc %</b-th>
              <b-th class="qty-cell" v-if="calcTotalDiscount">Discount</b-th>
              <b-th class="total-cell" v-if="calcTotalDiscount">Net Price</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(item,k) in caseProducts" :key="k">
              <b-td>{{item.product_name}}</b-td>
              <b-td>{{(item.currency ? item.currency : '') + ' ' + item.price}}</b-td>
              <b-td v-if="calcTotalDiscount">{{calcDiscount(item.price, item.discount)}}</b-td>
              <b-td v-if="calcTotalDiscount">{{(item.currency ? item.currency : '') + ' ' + item.discount}}</b-td>
              <b-td v-if="calcTotalDiscount">{{(item.currency ? item.currency : '') + (item.price - item.discount) }}</b-td>
            </b-tr>
            <b-tr>
              <b-th :colspan="calcTotalDiscount ? 4 : 1" class="text-right">Total Amount</b-th>
              <b-td>{{calcTotalCharge}}
              </b-td>
            </b-tr>
            <b-tr v-if="calcTotalDiscount">
              <b-th :colspan="calcTotalDiscount ? 4 : 1" class="text-right">Discount</b-th>
              <b-td>{{calcTotalDiscount}}
              </b-td>
            </b-tr>
            <b-tr v-if="calcTotalDiscount">
              <b-th :colspan="calcTotalDiscount ? 4 : 1" class="text-right">Net Amount</b-th>
              <b-td>{{calcLabCharges}}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col md="6" xs="12" class="mb-4">
        <b-card header="Communication History">
          <Comments :items="communicationHistory"/>
          <div class="d-flex justify-content-between">
          <b-button v-b-modal.modal-user-comment>Add Comment</b-button>
          <b-button variant="outline-secondary" @click="markAsRead()" class="mark-read pull-right" v-if="(uploadAllowed && tabIndex === 8) || (!uploadAllowed && tabIndex === 5)">Mark as Read</b-button>
          </div>
        </b-card>
        <b-form-checkbox
          v-if="parentCase === null && !uploadAllowed && !newCase && tabIndex < 3"
          id="checkbox-terms"
          v-model="agreeTerms"
          name="checkbox-terms"
          :value="false"
          :unchecked-value="true"
          class="mt-3"
        >
          I Agree the <a :href="publicPath + 'terms.pdf'" target="_blank">Terms and Conditions</a>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-5 mt-3">
        <b-button variant="dark" v-on:click="$bvModal.hide('modal-case-details')">Back to Cases</b-button>
      </b-col>
      <b-col class="mb-5 mt-3 text-center" v-if="parentCase === null && !uploadAllowed && tabIndex && tabIndex < 2">
        <b-button :disabled="disableRivisionBtn" v-b-modal.modal-revision-comment variant="outline-info">Need Revision</b-button>
      </b-col>
      <b-col class="mb-5 mt-3 text-center" v-if="uploadAllowed && tabIndex < 2">
        <b-button :disabled="disableRivisionBtn" v-if="!tabIndex" v-b-modal.modal-on-hold variant="outline-info">Keep on hold</b-button>
        <b-button :disabled="disableRivisionBtn" v-if="tabIndex" v-b-modal.modal-set-pending variant="outline-info">Set as pending</b-button>
      </b-col>
      <b-col class="mb-5 mt-3 text-right">
        <b-button :disabled="disableApproveBtn" v-if="uploadAllowed && parentCase === null && tabIndex < 3" v-on:click="updateERP()" variant="info">Send for Approval</b-button>
        <b-button :disabled="disableApproveBtn" v-if="uploadAllowed && parentCase === null && tabIndex === 3" v-on:click="updateERP()" variant="info">Modify Uploaded Files</b-button>
        <b-button :disabled="disableApproveBtn || agreeTerms" v-if="!uploadAllowed && parentCase === null && tabIndex && tabIndex < 3" v-on:click="showAddProductMsg()" variant="info">Approve Case</b-button>
        <b-button v-b-modal.modal-dispatch-action v-if="uploadAllowed && tabIndex === 4" variant="info">Printing Done</b-button>
      </b-col>
    </b-row>
    <b-modal
      id="modal-revision-comment"
      title="Revision Comments"
      footer-class="border-0"
      header-class="border-0"
      @ok="submitRevision"
    >
      <b-form-textarea
        v-model="revisionComments"
        id="textarea-comments"
        placeholder="Comments"
      ></b-form-textarea>
      <attachment-upload v-on:update-filename="revisionCommentFile = uploadUrl + $event" />
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="info" @click="ok()">
          Submit
        </b-button>
        <b-button size="sm" variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-user-comment"
      title="New Comment"
      footer-class="border-0"
      header-class="border-0"
      @ok="submitComment"
    >
      <b-form-textarea
        v-model="userComments"
        id="textarea-comments"
        placeholder="Comments"
      ></b-form-textarea>
      <attachment-upload v-on:update-filename="userCommentFile = baseUrl + $event" />
      <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="info" @click="ok()">
        Submit
      </b-button>
      <b-button size="sm" variant="dark" @click="cancel()">
        Cancel
      </b-button>
    </template>
    </b-modal>

    <b-modal
      id="modal-stl-preview"
      title="Preview STL"
      footer-class="border-0"
      header-class="border-0"
    >
      <iframe v-if="activeStl !==''" id="preview_stl_iframe" :src="'https://www.viewstl.com/?embedded&url=' + activeStl" style="border:0;margin:0;width:100%;height:100%;"></iframe>
      <template #modal-footer="{ ok }">
      <b-button size="sm" variant="info" @click="ok()">
       Cancel
      </b-button>
    </template>
    </b-modal>

    <b-modal
      id="modal-dispatch-action"
      title="Confirm Action"
      footer-class="border-0"
      header-class="border-0"
      @ok="dispatchAction"
    >
      <label for="dispatch-datepicker">Dispatch Date</label>
      <b-form-datepicker v-model="dispatchDate" :min="minDispatch" :max="maxDispatch" locale="en"></b-form-datepicker>
      <label for="input-no-of-aligners" class="mt-3">No. Of Aligners Sent:</label>
      <b-form-input type="number" v-model="noOfAligners" placeholder="Number of Aligners"></b-form-input>
      <label for="arch-sent" class="mt-3">Arch Sent:</label>
      <b-form-select v-model="archSent">
        <b-form-select-option v-for="(item, index) in archOptions"
          :key="index" :value="item">{{item}}</b-form-select-option>
      </b-form-select>
      <label for="free-refinement-datepicker" class="mt-3">Free Refinement Until</label>
      <b-form-datepicker v-model="freeRefinementDate" :min="minDispatch" locale="en"></b-form-datepicker>
      <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="info" @click="ok()">
        Submit
      </b-button>
      <b-button size="sm" variant="dark" @click="cancel()">
        Cancel
      </b-button>
    </template>
    </b-modal>

    <b-modal
      id="modal-on-hold"
      title="Confirm Action"
      footer-class="border-0"
      header-class="border-0"
      @ok="onHoldAction"
    >
      Are you sure to Keep on hold?
      <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="info" @click="ok()">
        Yes
      </b-button>
      <b-button size="sm" variant="dark" @click="cancel()">
        No
      </b-button>
    </template>
    </b-modal>

    <b-modal
      id="modal-set-pending"
      title="Confirm Action"
      footer-class="border-0"
      header-class="border-0"
      @ok="setPendingAction"
    >
      Are you sure to set as pending?
      <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="info" @click="ok()">
        Yes
      </b-button>
      <b-button size="sm" variant="dark" @click="cancel()">
        No
      </b-button>
    </template>
    </b-modal>

    <b-modal
      id="modal-product-form"
      title="Add Additional Products"
      footer-class="border-0"
      header-class="border-0"
      @ok="approveCase"
    >
    <b-overlay :show="skipAddProducts">
      <template #overlay>
        <div></div>
      </template>
      <b-table-simple hover small responsive>
        <b-thead head-variant="info">
          <b-tr>
            <b-th colspan="3">Product</b-th>
            <b-th colspan="1" class="qty-cell">Quantity</b-th>
            <b-th colspan="2">Unit Price</b-th>
            <b-th colspan="2">Sub Total</b-th>
            <b-th class="icon-wrap"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <product-line
            v-for="(product, k) in additionalProducts"
            :key="k"
            :index="k"
            :productList="productList"
            :product="product"
            :partnerId="item.partner_id[0]"
            @update-total="updateTotal"
            @remove-row="removeRow"
          />
          <b-tr>
            <b-td colspan="4"><b-link href="#" @click="addNewRow()">Add Product</b-link></b-td>
          </b-tr>
          <b-tr>
            <b-th colspan="6" class="text-right">Total</b-th>
            <b-td>
              <b-input-group size="sm" prepend="AED">
                <b-form-input class="ml-2" v-model="productsTotal" plaintext></b-form-input>
              </b-input-group>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-overlay>
      <b-form-checkbox
        id="checkbox-skip-products"
        v-model="skipAddProducts"
        name="checkbox-skip-products"
        :value="true"
        :unchecked-value="false"
        class="mt-3"
      >
        I do not need Additional Products
      </b-form-checkbox>
      <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="info" @click="ok()" :disabled="!additionalProducts.length && !skipAddProducts">
        Submit
      </b-button>
      <b-button size="sm" variant="dark" @click="cancel()">
        Cancel
      </b-button>
    </template>
    </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import api from '../api'
import config from '../config'
import FileUpload from '@/components/FileUpload.vue'
import Comments from '@/components/Comments.vue'
import AttachmentUpload from '@/components/AttachmentUpload.vue'
import { BIcon, BIconBullseye, BIconEye, BIconDownload, BIconFiles, BIconViewList, BIconCalendar, BIconHash, BIconCalendar2Date, BIconPersonFill, BIconPlusSquareFill } from 'bootstrap-vue'
import ProductLine from './ProductLine.vue'
import LabItem from './LabItem.vue'
export default {
  name: 'CaseDetails',
  props: {
    item: Object,
    tabIndex: Number
  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)
    // 15th in two months
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 2)
    maxDate.setDate(15)
    return {
      // currentItem: this.item,
      communicationHistory: [],
      minDispatch: minDate,
      maxDispatch: maxDate,
      name: '',
      approveRemarks: '',
      uploadRemarks: '',
      revisionComments: '',
      userComments: '',
      userCommentFile: null,
      revisionCommentFile: null,
      labCharges: null,
      labPrice: '',
      // uploadAllowed: this.$store.state.user.upload_clearsmile_file,
      image_url: '',
      // imageFile: { 'name': this.item.id + '.jpg' },
      stlFile: null,
      pdfFile: null,
      mpgFile: null,
      uploaded: false,
      stlUploaded: false,
      saleOrders: null,
      stlUab: this.item.stl_uab,
      stlLab: this.item.stl_lab,
      stlUaa: this.item.stl_uaa,
      stlLaa: this.item.stl_laa,
      stlOb: this.item.stl_ob,
      stlOa: this.item.stl_oa,
      pdfUrl: this.item.pdf_url,
      videoUrl: this.item.video_url,
      product: null,
      uploadUrl: config.filePath,
      baseUrl: config.serverURI,
      uploadHeaders: { },
      parentCase: null,
      productList: [],
      labItems: [{ product: null, price: 0, currency: '', discount: 0, discP: 0, net_price: 0 }],
      additionalProducts: [],
      // productsTotal: 0,
      disableRivisionBtn: false,
      disableCommentBtn: false,
      disableApproveBtn: false,
      agreeTerms: 1,
      skipAddProducts: false,
      publicPath: process.env.BASE_URL,
      attachmentCount: 0,
      attachmentList: [],
      stlViewer: null,
      // activeStl: this.item.stl_uab,
      activeStl: this.item.stl_uab,
      loadingStl: false,
      stlViewer2: null,
      activeStl2: this.item.stl_uaa,
      loadingStl2: false,
      dispatchDate: '',
      freeRefinementDate: '',
      noOfAligners: '',
      archSent: '',
      archOptions: ['', 'Upper', 'Lower', 'Uppder & Lower'],
      backgroundAlpha: 0,
      caseProducts: [],
      lights: [
        {
          type: 'AmbientLight',
          color: 0x202020
        },
        {
          type: 'DirectionalLight',
          position: { x: 0, y: 0, z: 2 },
          color: 0xffffff,
          intensity: 0.6
        },
        {
          type: 'PointLight',
          position: { x: 0, y: -25, z: 10 },
          color: 0xffffff,
          intensity: 0.3
        }
      ]
    }
  },
  components: {
    FileUpload,
    Comments,
    ProductLine,
    LabItem,
    AttachmentUpload,
    BIcon,
    // BIconTrash,
    BIconFiles,
    BIconEye,
    BIconDownload,
    BIconHash,
    BIconCalendar2Date,
    BIconPersonFill,
    BIconPlusSquareFill,
    BIconCalendar,
    BIconBullseye,
    BIconViewList
  },
  computed: {
    getPatient: function () {
      return this.item.first_name + ' ' + this.item.last_name
    },
    getDentist: function () {
      return this.item.dentist_firstname + ' ' + this.item.dentist_lastname
    },
    uploadAllowed: function () {
      return this.$store.state.user.user_role === '12'
    },
    labChargeItems: function () {
      return this.$store.state.productList
    },
    labItemsArray: function () {
      var productDetails = this.labItems.map(v => ({ 'product_id': v.product, 'price': v.price, 'discount': v.discount, 'currency': v.currency }))
      return productDetails
    },
    labItemList: function () {
      var productDetails = this.labItemsArray.filter(v => { return v.product_id })
      return productDetails
    },
    totalLabCharge: function () {
      var total = this.labItems.reduce((acc, item) => acc + Number(item.price), 0)
      return total
    },
    totalLabDiscount: function () {
      var total = this.labItems.reduce((acc, item) => acc + Number(item.discount), 0)
      return total
    },
    calcTotalCharge: function () {
      var total = this.caseProducts.reduce((acc, item) => acc + Number(item.price), 0)
      return total
    },
    calcTotalDiscount: function () {
      var total = this.caseProducts.reduce((acc, item) => acc + Number(item.discount), 0)
      return total
    },
    calcLabCharges: function () {
      return this.calcTotalCharge - this.calcTotalDiscount
    },
    newCase: function () {
      return !this.tabIndex
    },
    stlFiles: function () {
      var options = [
        { text: 'Upper Arch Before', value: this.item.stl_uab },
        // { text: 'Upper Arch After', value: this.item.stl_uaa },
        { text: 'Lower Arch Before', value: this.item.stl_lab },
        // { text: 'Lower Arch After', value: this.item.stl_laa },
        { text: 'Occlusion Before', value: this.item.stl_ob }
        // { text: 'Occlusion After', value: this.item.stl_oa }
      ]
      var i
      for (i = 0; i < options.length; i++) {
        if (options[i].value === '') {
          options[i].disabled = true
        }
      }
      return options
    },
    stlFiles2: function () {
      var options = [
        { text: 'Upper Arch After', value: this.item.stl_uaa },
        { text: 'Lower Arch After', value: this.item.stl_laa },
        { text: 'Occlusion After', value: this.item.stl_oa }
      ]
      var i
      for (i = 0; i < options.length; i++) {
        if (options[i].value === '') {
          options[i].disabled = true
        }
      }
      return options
    },
    currentItem: function () {
      return this.item
    },
    patientName: function () {
      return this.item.patient_id ? this.item.patient_id[0][1] : ''
    },
    getStlPath: function () {
      return this.uploadUrl + this.currentItem.id + '-uab.stl'
    },
    imageUrl: function () {
      return this.uploadUrl + this.currentItem.id + '.mp4'
    },
    getPdfUrl: function () {
      return this.uploadUrl + this.currentItem.id + '.pdf'
    },
    getPrice: function () {
      if (this.labCharges) {
        console.log(this.labCharges.price.toString())
        return this.labCharges.price.toString()
      } else {
        return ''
      }
    },
    getCurrency: function () {
      if (this.labCharges) {
        return this.labCharges.currency
      } else {
        return ''
      }
    },
    productsTotal: function () {
      var total = this.additionalProducts.reduce((acc, item) => acc + item.sub_total, 0)
      return total
    },
    getAttachments: function () {
      var i
      var url
      var list = []
      for (i = 1; i < 7; i++) {
        url = this.item['attachment' + i]
        if (url !== '') {
          if (url.match(/.(jpg|jpeg|png|gif)$/i)) {
            list.push({ 'label': 'File ' + i, 'url': url, 'isImage': true })
          } else {
            list.push({ 'label': 'File ' + i, 'url': url, 'isImage': false })
          }
        }
      }
      return list
    }
  },
  methods: {
    getDateTime: function (datetime) {
      // Split timestamp into [ Y, M, D, h, m, s ]
      var t = datetime.split(/[- :]/)
      // Apply each element to the Date function
      var date = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
      var hours = date.getHours()
      var minutes = date.getMinutes()
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours !== 0 ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes
      var strTime = hours + ':' + minutes + ' ' + ampm
      const month = Number(date.getMonth()) + 1
      return date.getDate() + '/' + month + '/' + date.getFullYear() + ' ' + strTime
    },
    showAddProductMsg: function () {
      // this.$bvModal.show('modal-product-form')
      this.approveCase()
    },
    stlLoaded: function () {
      this.loadingStl = false
    },
    loadStl: function () {
      switch (this.activeStl) {
        case this.item.stl_uab : this.activeStl2 = this.item.stl_uaa
          break
        case this.item.stl_lab : this.activeStl2 = this.item.stl_laa
          break
        case this.item.stl_ob : this.activeStl2 = this.item.stl_oa
          break
      }
      /* eslint-disable no-undef */
    },
    stlLoaded2: function () {
      this.loadingStl2 = false
    },
    loadStl2: function () {
      switch (this.activeStl2) {
        case this.item.stl_uaa : this.activeStl = this.item.stl_uab
          break
        case this.item.stl_laa : this.activeStl = this.item.stl_lab
          break
        case this.item.stl_oa : this.activeStl = this.item.stl_ob
          break
      }
    },
    removeRow: function ($event) {
      // this.productsTotal -= $event.sub_total
      this.additionalProducts = this.additionalProducts.filter(function (obj) {
        return obj.product_id !== $event.product_id
      })
    },
    addNewRow: function () {
      this.additionalProducts.push({
        product_id: '',
        quantity: '',
        price: '',
        sub_total: 0
      })
    },
    updateLabCharge: function ($event) {
      this.labItems[$event.key].product = $event.product_id
      this.labItems[$event.key].discount = $event.discount
      this.labItems[$event.key].price = $event.price
      this.labItems[$event.key].net_price = $event.sub_total
      this.labItems[$event.key].discP = $event.discount_percent
      this.labPrice = $event.price
      this.labCharges = $event
      // this.productsTotal += $event.sub_total
    },
    updateTotal: function ($event) {
      this.additionalProducts[$event.key].product_id = $event.product_id
      this.additionalProducts[$event.key].quantity = $event.quantity
      this.additionalProducts[$event.key].price = $event.price
      this.additionalProducts[$event.key].sub_total = $event.sub_total
      // this.productsTotal += $event.sub_total
    },
    backToParent: function () {
      this.$emit('update-case', this.parentCase)
      this.parentCase = null
    },
    getCommunicationHistory: function () {
      const inputs = {
        filter: {
          'case_id': this.item.case_id
        }
      }
      console.log('Communication history input', inputs)
      api
        .request('post', 'GeneralApis/get_communication_history', inputs)
        .then((response) => {
          console.log('Communication History API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
          }
          if (data.communicationList) {
            console.log('sale order list:', data.communicationList)
            this.communicationHistory = data.communicationList
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    submitComment: function () {
      this.disableCommentBtn = true
      var inputs = {
        'form-data': {
          comment: this.userComments,
          case_id: this.item.case_id
        }
      }
      /*
      if (this.userCommentFile) {
        inputs.inputs.args.file = this.userCommentFile
      } */
      console.log('User Comment input', inputs)
      api
        .request('post', 'GeneralApis/add_communication', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
          }
          if (data.communicationList) {
            console.log('Update communication:', data.communicationList)
            this.communicationHistory = data.communicationList
            /*
            this.$bvToast.toast(data.result[0], {
              title: 'Submited',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            this.updateDetails()
            */
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    submitRevision: function () {
      this.disableRivisionBtn = true
      var inputs = {
        'form-data': {
          comment: this.revisionComments,
          case_id: this.item.case_id
        }
      }
      console.log('Revision Comment input', inputs)
      var self = this
      api
        .request('post', 'PortalApis/need_revision', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            self.disableRivisionBtn = false
            self.$bvToast.toast(data.errormessage, {
              title: 'Failed',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
          } else {
            console.log('Revision message:', data.errormessage)
            self.$bvToast.toast(data.errormessage, {
              title: 'Approved',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            self.$emit('refresh-cases')
            self.$bvModal.hide('modal-case-details')
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    downloadItem: function (imageUrl, name) {
      axios.get(imageUrl, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'image/*' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = name
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    updateERP: function () {
      this.disableApproveBtn = true
      var inputs = {
        'form-data': {
          case_id: this.currentItem.case_id,
          case_name: this.currentItem.case_name,
          first_name: this.currentItem.first_name,
          last_name: this.currentItem.last_name,
          email: this.currentItem.email,
          pdf_url: this.pdfUrl,
          video_url: this.videoUrl,
          stl_uab: this.stlUab,
          stl_lab: this.stlLab,
          stl_uaa: this.stlUaa,
          stl_laa: this.stlLaa,
          stl_ob: this.stlOb,
          stl_oa: this.stlOa,
          remark: this.uploadRemarks,
          created_by: this.currentItem.created_by
        }
      }
      if (this.tabIndex < 3) {
        inputs['form-data'].product_list = this.labItemList
      }
      console.log('upload case files input', inputs)
      api
        .request('post', 'PortalApis/upload_case_files', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          var self = this
          console.log('API Response Data:', data)
          if (data.errorcode) {
            self.disableApproveBtn = false
            self.$bvToast.toast(data.errormessage, {
              title: 'Failed',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
          } else {
            console.log('Approve message:', data.errormessage)
            self.$bvToast.toast(data.errormessage, {
              title: 'Updated',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            self.$emit('refresh-cases')
            self.$bvModal.hide('modal-case-details')
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    approveCase: function () {
      this.disableApproveBtn = true
      var inputs = {
        'form-data': {
          comment: this.approveRemarks,
          case_id: this.item.case_id
        }
      }
      console.log('Approve case input', inputs)
      var self = this
      api
        .request('post', 'PortalApis/approve_case', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            self.disableApproveBtn = false
            self.$bvToast.toast(data.errormessage, {
              title: 'Failed',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
          } else {
            console.log('Approve message:', data.errormessage)
            self.$bvToast.toast(data.errormessage, {
              title: 'Approved',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            self.$emit('refresh-cases')
            self.$bvModal.hide('modal-case-details')
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    onHoldAction: function () {
      this.disableRivisionBtn = true
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'keep_on_hold',
          'args': { 'sale_order_id': this.currentItem.id }
        },
        'modecode': 100
      }
      console.log('Keep on hold input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Keep on hold:', data.result)
            var self = this
            this.$bvToast.toast(data.result[0], {
              title: 'Holded',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            self.$emit('refresh-cases')
            setTimeout(function () {
              self.$emit('back-to-cases')
            }, 500)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    setPendingAction: function () {
      this.disableRivisionBtn = true
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'set_as_pending',
          'args': { 'sale_order_id': this.currentItem.id }
        },
        'modecode': 100
      }
      console.log('Pending input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Set Pending:', data.result)
            var self = this
            this.$bvToast.toast(data.result[0], {
              title: 'Pending Upload',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            self.$emit('refresh-cases')
            setTimeout(function () {
              self.$emit('back-to-cases')
            }, 500)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    getLabCharges: function (index) {
      const inputs = {
        filter: {
          'user_id': this.item.created_by,
          'product_id': this.labItems[index].product
        }
      }
      console.log('lab charges input', inputs)
      api
        .request('post', 'PortalApis/get_lab_charge', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            if (this.labCharges) {
              this.labCharges.price = 0
            } else {
              this.labCharges = { price: 0, currency: 'AED' }
            }
          }
          if (data.productList) {
            console.log('Lab charge list:', data.productList)
            this.labCharges = data.productList[0]
          }
          this.labItems[index].price = this.labCharges.price
          this.labItems[index].currency = this.labCharges.currency
          this.labPrice = this.labCharges.price
          setTimeout(function () {
          }, 500)
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    getRelatedCase: function (value) {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'sale.order',
          'method': 'get_related_case',
          'args': { 'sale_order_id': value }
        },
        'modecode': 100
      }
      console.log('Related Case input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('sale order list:', data.result)
            this.parentCase = this.currentItem
            this.$emit('update-case', data.result[0])
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    getAdditionalProducts: function (value) {
      const inputs = {
        'inputs': {
          'session_id': this.$store.state.token,
          'model': 'case.type',
          'method': 'get_additional_products',
          'args': {}
        },
        'modecode': 100
      }
      console.log('Additional Products input', inputs)
      api
        .request('post', 'update', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            this.response = data.errormessage
            return
          }
          if (data.result) {
            console.log('Products list:', data.result)
            this.productList = data.result[0].additional_product_ids
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    getCaseProducts: function () {
      const inputs = {
        filter: {
          'case_id': this.item.case_id
        }
      }
      console.log('Case Products input', inputs)
      api
        .request('post', 'PortalApis/get_case_products', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.productList) {
            console.log('Case Product list:', data.productList)
            this.caseProducts = data.productList
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    updateDetails: function () {
      this.$emit('reload-case', this.currentItem.id)
    },
    removeLabItem: function (item) {
      // this.productsTotal -= $event.sub_total
      this.labItems = this.labItems.filter(function (obj) {
        return obj.product !== item.product
      })
    },
    addLabItem: function () {
      this.labItems.push({ product: null, price: 0, net_price: 0, currency: '', discount: 0, discP: 0 })
    },
    updateFileName: function (fileName = null) {
      if (fileName) {
        this.image_url = 'https://portal.clearsmileme.com/api_middleware' + fileName
      } else {
        this.image_url = this.uploadUrl + this.currentItem.id + '.pdf'
      }
    },
    dispatchAction: function () {
      this.disableApproveBtn = true
      var inputs = {
        'form-data': {
          aligner_no: this.noOfAligners,
          case_id: this.item.case_id,
          dispatch_date: this.dispatchDate,
          arch_sent: this.archSent,
          free_refinement_date: this.freeRefinementDate
        }
      }
      console.log('Printing Done input', inputs)
      var self = this
      api
        .request('post', 'PortalApis/printing_done', inputs)
        .then((response) => {
          console.log('API Response:', response)
          var data = response.data
          console.log('API Response Data:', data)
          if (data.errorcode) {
            self.disableApproveBtn = false
            self.$bvToast.toast(data.errormessage, {
              title: 'Failed',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
          } else {
            console.log('Printing Done message:', data.errormessage)
            self.$bvToast.toast(data.errormessage, {
              title: 'Dispatched',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
            setTimeout(function () {
              self.$emit('refresh-cases')
              self.$bvModal.hide('modal-case-details')
            }, 500)
          }
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
        .catch((error) => {
          console.log('What happened? ' + error)
        })
    },
    markAsRead: function () {
      this.$emit('show-message', this.item.id)
    },
    calcDiscount: function (price, discount) {
      let perct = 0
      if (discount) {
        perct = discount / price * 100
      }
      return perct
    }
  },
  mounted () {
    this.getCommunicationHistory()
    if ((!this.uploadAllowed && this.parentCase === null && !this.newCase) || (this.uploadAllowed && this.tabIndex > 2)) {
      this.getCaseProducts()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#case-details .list-group-item-secondary{
  background: #F2F5F9;
}
#case-details .list-group-item{
  border: none;
}
h1 {
  font-size: 24px;
}
  #file-upload-wrap .drop-help-text {
    display: none;
  }
  #file-upload-wrap .is-drag-over .drop-help-text {
    display: block;
  }
  #file-upload-wrap .profile-pic-upload-block {
    border: 2px dashed transparent;
    padding: 20px;
    padding-top: 0;
  }

  #file-upload-wrap .is-drag-over.profile-pic-upload-block {
    border-color: #AAA;
  }
  #file-upload-wrap .vue-file-agent {
    width: 180px;
    float: left;
    margin: 0 15px 5px 0;
    border: 0;
    box-shadow: none;
  }
  .stl-wrap{
    height: 300px;
  }
  .icon-wrap{
    width: 30px;
  }
  .qty-cell{
    width: 60px;
  }
  .total-cell{
    width: 100px;
  }
  .remove-item{
    cursor: pointer;
  }
</style>

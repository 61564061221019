<template>
  <div>
    <Header/>
    <b-container>
      <b-row class="section1">
        <b-col cols="12" md="6">
          <b-img fluid :src="require('@/assets/images/home/teeth-straightening.jpg')"></b-img>
        </b-col>
        <b-col cols="12" md="6">
          <h1>The clear German way of teeth straightening.</h1>
          <p>We applied a state of biomechanical technology to organize orthodontic malocclusion, and design an integrated treatment protocol and high-quality aligners to boost the patient self-esteem by turning on their beauty smile.</p>
        </b-col>
      </b-row>
      <b-row class="section2">
        <b-col>
          <h2>Which type of bites can Deutsche Aligners treat?</h2>
          <p class="sub-heading">Choose a Deutsche Treatment Delivered by Deutsche trained dentist</p>
          <p class="content">Treating all kinds of malocclusion (spacing, crowding, open bite, deep bite, cross bite and scissor bite) and treating the complex and severe cases specially class 3, class 2 and achieving class 1 with predictable protocol of moving the teeth.</p>
          <b-img fluid :src="require('@/assets/images/home/types-of-bites.jpg')"></b-img>
        </b-col>
      </b-row>
      <b-row class="section4">
        <b-col>
          <h2>Our Aligners, Your Choice</h2>
          <b-card-group deck>
            <b-card>
              <h3>Want straighter teeth in as little as six months?</h3>
              <p>Consider our discreet braces for great results fast</p>
              <b-button variant="outline-primary">Learn More</b-button>
            </b-card>
            <b-card>
              <h3>Want your teeth straightened invisibly?</h3>
              <p>Find out how Deutsche Aligner can straighten your teeth without ever being seen</p>
              <b-button variant="outline-primary">Learn More</b-button>
            </b-card>
            <b-card>
              <h3>Need a few minor tweaks to perfect your smile?</h3>
              <p>The Super Deutsche Align Package could be the answer</p>
              <b-button variant="outline-primary">Learn More</b-button>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
      <StartTreatement/>
      <b-row class="section6">
        <b-col>
          <h2>Why Deutsche Aligners?</h2>
          <b-card-group deck>
            <b-card class="yellow">
              <b-img :src="require('@/assets/images/home/box-icon-yellow.png')"></b-img>
              <h3>AESTHETICS AND CLEAR</h3></b-card>
            <b-card class="blue">
              <b-img :src="require('@/assets/images/home/box-icon-blue.png')"></b-img>
              <h3>MORE TIME AND EFFICIENT</h3></b-card>
            <b-card class="purple">
              <b-img :src="require('@/assets/images/home/box-icon-purple.png')"></b-img>
              <h3>LESS INCIDENCE AND SEVERITY OF ROOT RESORPTION</h3></b-card>
            <b-card class="green">
              <b-img :src="require('@/assets/images/home/box-icon-green.png')"></b-img>
              <h3>GENTLE ON THE GUM</h3></b-card>
          </b-card-group>
        </b-col>
      </b-row>
      <b-row class="section7">
        <b-col cols="12" md="6">
          <b-img :src="require('@/assets/images/home/deutsche-treatment-process.jpg')" fluid></b-img>
        </b-col>
        <b-col cols="12" md="6" class="wrap-col">
          <div class="wrap">
            <h2>Turn on your beauty smile by Deutsche aligners treatment process</h2>
            <p>Find Deutsche Aligners certified dentist nearby</p>
            <b-button variant="primary" class="btn-primary">Book Your Visit Today</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="section8">
        <dentist-search-tiles/>
      </b-row>
    </b-container>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import StartTreatement from '@/components/StartTreatment.vue'
import DentistSearchTiles from '../components/DentistSearchTiles.vue'

export default {
  name: 'Home',
  data () {
    return {
    }
  },
  components: {
    Header,
    Footer,
    StartTreatement,
    DentistSearchTiles
  }
}
</script>

<style lang="scss" scoped>
.section1{
  h1{
    font-size: 54px;
    line-height: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  p{
    font-size: 18px;
  }
}
.section2{
  margin-top: 100px;
  text-align: center;
  h2{
    font-size: 42px;
    line-height: 50px;
  }
  .sub-heading{
    font-size: 20px;
    line-height: 28px;
  }
  .content{
    font-size: 18px;
    line-height: 22px;
  }
}
.section3{
  margin-top: 40px;
  h3{
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  p{
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 40px;
  }
}
.section4{
  margin-top: 100px;
  text-align: center;
  h2{
    margin-bottom: 45px;
  }
  .card{
    background: #F2F5FF;
    .card-body{
      padding: 45px;
    }
  }
  h3{
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  p{
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 25px;
    display: block;
    min-height: 56px;
  }
}
.section6{
  margin-top: 100px;
  text-align: center;
  h2{
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 50px;
  }
  h3{
    margin-top: 25px;
    font-size: 20px;
    line-height: 28px;
  }
  p{
    font-size: 16px;
    line-height: 22px;
  }
  .card-body{
    padding: 40px 15px 0px;
  }
  .yellow{
    border: 1px solid #F3D076;
    background: #FFFBF2;
    h3{
      margin-bottom: 50px;
    }
  }
  .blue{
    border: 1px solid #7E95EA;
    background: #F2F5FF;
    h3{
      margin-bottom: 15px;
    }
  }
  .purple{
    background: #FFF2FD;
    border: 1px solid #C370C0;
  }
  .green{
    background: #F6FFF2;
    border: 1px solid #B0CFB3;
  }
}
.section7{
  margin-top: 100px;
  .wrap{
    margin-left: 70px;
    vertical-align: middle;
    margin-top: 20px;
  }
  h2{
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 40px;
  }
  p{
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 40px;
  }
}
.section8{
  margin-top: 90px;
  margin-bottom: 70px;
  text-align: center;
}
@media (min-width:768px) {
  .section1, .section7{
    flex-direction: row-reverse;
  }
  .section1{
    h1{
    }
  }
  .section3{
    h3{
      margin-top: 0;
    }
  }
  .section7{
    h2{
      margin-top: 0;
    }
    .wrap-col{
      display: flex;
      align-items: center;
    }
  }
}
</style>

<template>
    <image :height="height" :width="width" :id="id" :xlink:href="require('@/assets/images/tooth/' + getLink + '')" @click="selectTeeth()" :x="x" :y="y"></image>
</template>

<script>
export default {
  name: 'Tooth',
  data () {
    return {
      isActive: false,
      imgSrc: this.xlink
    }
  },
  props: {
    id: String,
    height: String,
    width: String,
    x: String,
    y: String
  },
  computed: {
    getLink: function () {
      if (this.isActive) {
        return 'tooth' + this.id + 'h.png'
      } else {
        return 'tooth' + this.id + '.png'
      }
    }
  },
  methods: {
    hove_on: function (item) {
      console.log('on', item)
    },
    hove_out: function (item) {
      console.log('out', item)
    },
    toggleActive: function () {
      this.isActive = !this.isActive
    },
    selectTeeth: function () {
      if (this.isActive) {
        this.isActive = false
        this.$store.commit('REMOVE_TEETH', this.id)
      } else {
        this.isActive = true
        this.$store.commit('ADD_TEETH', this.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
image {
    cursor: pointer;
}
</style>
